import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseURL } from '../../components/BaseURL';
import { UserContext } from '../../App';
import PieChart from '../../components/PieChart';

export default function SearchV3({ country }) {
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [area, setArea] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noAds, setNoAds] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [paidSubscription, setPaidSubscription] = useState(false);
  const [visible, setVisible] = useState(false);
  const [continent, setContinent] = useState([]);
  const [errorMessage, setErrorMessage] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [inputPageValue, setInputPageValue] = useState("")
  const [isPrev, setIsPrev] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [sortBy, setSortBy] = useState("award_year")


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(baseURL + '/get-profile-data', {
          method: 'GET',
          headers: {
            Authorization: 'Token ' + localStorage.getItem('classified-ads-token'),
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.subscription_type === 'paid') {
          setPaidSubscription(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (country) {
      setArea(country);
      getAdsV3(country, "", 1, sortBy)
    }
  }, [country]);

  const getContinentData = async (adQuery) => {
    fetch(`${baseURL}/v1/ads-continents/${adQuery}/`)
      .then(res=>res.json())
      .then(data => {
        setLoading(true)
        setContinent(data["continent"])
        setLoading(false)
      })
  }

  const getAdsV3 = async (area, query, pageNo, sort_by) => {
    if (query.length <= 0 && area) {
      setContinent([])
      fetch(`${baseURL}/v3/search-area-ads/${area}/${pageNo}?sort_by=${sort_by}`)
        .then(res => res.json())
        .then(data => {
          setResults(data['ads'])
          if (data['ads'].length <= 0) setNoAds(true)
          setActivePage(pageNo)
          setTotalPage(data['total_pages'])
          setIsNext(data['next'])
          setIsPrev(data['previous'])
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          setErrorMessage("Something went wrong. Please try again later.")
        })
    }
    else if (query && area.length <= 0)
    {
      fetch(`${baseURL}/v3/search-query-ads/${query}/${pageNo}?sort_by=${sort_by}`)
        .then(res=>res.json())
        .then(data => {
          setResults(data['ads'])
          if (data['ads'].length <= 0) setNoAds(true)
          setActivePage(pageNo)
          setTotalPage(data['total_pages'])
          setIsNext(data['next'])
          setIsPrev(data['previous'])
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          setErrorMessage("Something went wrong. Please try again later.")
        })
    } else if (query.length > 2 && area.length > 2)
    {
      fetch(`${baseURL}/v3/search-ads/${area}/${query}/${pageNo}?sort_by=${sort_by}`)
        .then(res=>res.json())
        .then(data => {
          setResults(data['ads'])
          if (data['ads'].length <= 0) setNoAds(true)
          setActivePage(pageNo)
          setTotalPage(data['total_pages'])
          setIsNext(data['next'])
          setIsPrev(data['previous'])
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          setErrorMessage("Something went wrong. Please try again later.")
        })
    } else {
      // setResults([])
    }
  }
  const handleNumberButtonClick = (event) => {
    if (activePage === parseInt(event.target.textContent, 10)) return null;
    // setResults([])
    setLoading(true)
    getAdsV3(area, query, parseInt(event.target.textContent, 10), sortBy)
  }

  const handleNextButtonClick = async () => {
    // setResults([])
    setLoading(true)
    getAdsV3(area, query, activePage + 1, sortBy)
  }

  const handlePrevButtonClick = async () => {
    if (activePage > 1 )
    {
      // setResults([])
      setLoading(true)
      getAdsV3(area, query, activePage-1, sortBy)
    }
    return null
  }

  const handlePageNoSearch = async () => {
    // setResults([])
    setLoading(true)
    setInputPageValue("")
    getAdsV3(area, query, inputPageValue, sortBy)
  }

  const handleChangePageInput = event => {
    if (event.target.value.length === 0)
    {
      setInputPageValue("")
      return
    }
    const value = Math.max(1, Math.min(totalPage, Number(event.target.value)));
    setInputPageValue(value);
  };



  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (query === '' && area === '') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill at least one field',
        });
      } else {
        setLoading(true);
        setNoAds(false);
        // setResults([]);
        setShowLoadMore(false);
        if (query)
        {
          getContinentData(query)
        }
        getAdsV3(area, query, 1, sortBy);

        const date = new Date();
        date.setTime(date.getTime() + 5 * 60 * 1000);

        document.cookie = `queryValueCookie=${query}; expires=${date.toUTCString()}; path=/`;
        document.cookie = `areaValueCookie=${area}; expires=${date.toUTCString()}; path=/`;
      }
    }
  };

  const handleSubmit = () => {
    if (query === '' && area === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill at least one field',
      });
    } else {
      setLoading(true);
      setNoAds(false);
      // setResults([]);
      setShowLoadMore(false);
      if (query)
      {
        getContinentData(query)
      }
      getAdsV3(area, query, 1, sortBy);

      const date = new Date();
      date.setTime(date.getTime() + 5 * 60 * 1000);

      document.cookie = `queryValueCookie=${query}; expires=${date.toUTCString()}; path=/`;
      document.cookie = `areaValueCookie=${area}; expires=${date.toUTCString()}; path=/`;
    }
  };

  useEffect(() => {
    if (country) {
      setArea(country)
    }
  }, [country])

  useEffect(() => {
    // check queryValueCookie and areaValueCookie in cookie
    const queryValueCookie = document.cookie.replace(/(?:(?:^|.*;\s*)queryValueCookie\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const areaValueCookie = document.cookie.replace(/(?:(?:^|.*;\s*)areaValueCookie\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (queryValueCookie || areaValueCookie) {
      setQuery(queryValueCookie)
      setArea(areaValueCookie)
      if (queryValueCookie)
      {
        getContinentData(queryValueCookie)
      }
      getAdsV3(areaValueCookie, queryValueCookie, 1, sortBy)
      setLoading(true)
      setNoAds(false)
      // setResults([])
      setShowLoadMore(false)
    }
  }, [])

  function pagination() {
    if (totalPage <=5)
    {
      return (
        <>
          {
            isPrev ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            ) : null
          }
          {
            Array.from({ length: totalPage }, (_, index) => (
              <button
                key={index + 1}
                className={`paginationButton join-item btn btn-sm ${index + 1 === activePage ? 'paginationButtonActive' : ''}`}
                onClick={handleNumberButtonClick}
              >
                {index + 1}
              </button>
            ))
          }
          {
            isNext ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
            ) : null
          }
        </>
      )
    } else {
      if (activePage < 5)
      {
        return (
          <>
            {
              isPrev ? (
                <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
              ) : null
            }
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${
                    index + 1 === activePage ? 'paginationButtonActive' : ''
                  }`}
                  onClick={handleNumberButtonClick}
                >
                  {index + 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 <= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${activePage + index - 1  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {activePage + index - 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 >= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${totalPage-5+1+index  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {totalPage-5+1+index}
                </button>
              ))
            }
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      }
    }
  }
  const handleSortByChange = (event) => {
    if (event.target.value !== sortBy){
      setSortBy(event.target.value);
      setLoading(true);
      getAdsV3(area, query, 1, event.target.value)
    }
  };
  return (
    <div>
      <div className="mt-5 flex items-center justify-center">
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          placeholder="Looking for?(Occupation or Year)"
          className="bg-gray-100 rounded-md px-3 mx-1 py-3 outline-none border-none w-1/3 lg:w-1/3"
          onKeyDown={handleKeyPress}
        />
        <input
          value={area}
          onChange={(e) => setArea(e.target.value)}
          type="text"
          placeholder="Where?"
          className="bg-gray-100 rounded-md px-3 mx-1 py-3 outline-none border-none  w-1/3 lg:w-1/3"
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={handleSubmit}
          className="btn border-none px-4 py-3 bg-[#FFCC40] text-black rounded-md mx-1 hover:bg-[#FFCC40] hover:border-red-950"
        >
          Search
        </button>
      </div>
      <div className="flex items-center justify-center text-xs italic tracking-[.20em]"> <p>use comma(,) for multi-word search</p></div>

      {paidSubscription ? (
        <></>
      ) : (
        <>
          {visible ? (
            <div className="flex justify-center mt-5">
              <Link to="/upgrade">
                <button
                  className="animate-pulse text-xs px-4 py-1 bg-opacity-20 text-black text-center rounded-full bg-[#FFCC40]">
                  Upgrade to pro & get premium data
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {continent.length !== 0 ? (
        <div className="my-10 w-3/3 lg:w-3/4 mx-auto">
          <PieChart continent={continent}/>
        </div>
      ) : null}

      <div className="my-10 w-3/3 lg:w-3/4 mx-auto">
        {
          results.length > 0 ? (
            <div className="mb-2">
              <div className="flex flex-row justify-end items-center">
                <div className="form-control mr-2">
                  <label className="label cursor-pointer">
                    <span className="label-text font-bold mr-2">Sort By </span>
                    <select
                      value={sortBy}
                      onChange={handleSortByChange}
                      className="select select-warning hover:outline-0 focus:outline-0"
                    >
                      <option value="award_year">Award Year</option>
                      <option value="alphabet">Alphabet</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          ) : null
        }
        {results.map((result, index) => {
          const description = result.description;
          let awardYear = '';
          if (description.includes('Award Year : ')) {
            awardYear = description.split('Award Year : ')[1].split('\n')[0];
          } else if (description.includes('Year : ')) {
            awardYear = description.split('Year : ')[1].split('\n')[0];
          }

          return (
            <div
              key={index}
              onClick={() => {
                if (result.is_premium) {
                  if (userData.userData.subscription_type === 'paid') {
                    navigate(`/data/${result.id}`);
                  } else {
                    Swal.fire({
                      title: 'You need to subscribe to view premium data',
                      text: 'Do you want to subscribe now?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, subscribe now!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate('/upgrade');
                      }
                    });
                  }
                } else {
                  navigate(`/data/${result.id}`);
                }
              }}
            >
              <div
                className={`flex justify-between items-center bg-gray-100 pr-4 hover:bg-gray-300 transition duration-100  rounded mb-2 lg:pl-7 py-3  cursor-pointer text-gray-500 hover:text-gray-800 p-3 ${
                  result.is_premium ? 'cursor-pointer' : ''
                }`}
              >
                <h3>
                  {
                    awardYear ? (<span className="text-gray-500 mr-5">{awardYear}</span>) : null
                  }
                  {result.title}
                </h3>
                {result.is_premium ? <div>&#128274;</div> : null}
              </div>
            </div>
          );
        })}
      </div>


      <div className="text-center">
        {loading ? <div>Loading...</div> : null}
        {noAds ? <div>No data found</div> : null}
        {errorMessage ? <div>{errorMessage}</div> : null}

        <div className="join mb-6">
          {
            totalPage > 1 ? pagination() : null
          }
        </div>
      </div>
    </div>
  );
}
