import React from 'react';
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {baseURL} from '../../components/BaseURL';
import SelectArea from './SelectArea';
import SelectCity from './SelectCity';
import {data} from "autoprefixer";


const AdPost = () => {
  const {specificarea} = useParams();
  const [areas, setAreas] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);

  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [selectedContinent, setSelectedContinent] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedArea, setSelectedArea] = React.useState('');
  const [countryNameFromIp, setCountryNameFromIp] = React.useState('');
  const [stateNameFromIp, setStateNameFromIp] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  let hasArea = false;

  // useRef
  const fetchAreas = React.useRef(true);
  const fetchCategories = React.useRef(true);

  const {register, handleSubmit, reset, formState: {errors}, setValue} = useForm();
  const onSubmit = data => {
    setLoading(true);

    let formdata = new FormData();
    formdata.append('title', data.title);
    formdata.append('description', data.description);
    formdata.append('category', data.category);
    formdata.append('sub_category', data.sub_category);
    formdata.append('area', data.area);
    formdata.append('city', data.city);
    formdata.append('state', data.state);
    formdata.append('country', data.country);
    formdata.append('continent', data.continent);
    if (data.image.length > 0) {
      formdata.append('image', data.image[0]);
    }
    formdata.append('email', data.email);
    formdata.append('phone', data.phone);
    formdata.append('postal_code', data.postal_code);
    formdata.append('street', data.street);

    fetch(`${baseURL}/create-ads`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${localStorage.getItem('classified-ads-token')}`,
        //"Content-Type": "application/json",
        //'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
      },
      body: formdata
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        if (res.status === 'success') {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your post has been submitted.',
            showConfirmButton: false,
            timer: 1500
          })
          reset();
        } else if (res.detail === 'Invalid token.') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Session expired. Please login again.',
          })
          localStorage.removeItem('classified-ads-token');
          window.location.href = '/login';
        } else {
          let title = 'Something went wrong!';
          if (res.message.email) {
            title = res.message.email;
          }
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: title,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
      .catch(err => {
        console.log(err);
      })
  };

  React.useEffect(() => {
    if (fetchAreas.current) {
      fetchAreas.current = false;
      fetch(`${baseURL}/get-all-areas`)
        .then(res => res.json())
        .then(data => {
          setAreas(data);
        })
        .catch(err => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    if (fetchCategories.current) {
      fetchCategories.current = false;
      fetch(`${baseURL}/choose-category`)
        .then(res => res.json())
        .then(data => {
          setCategories(data);
        })
        .catch(err => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    if (selectedCategory) {
      fetch(`${baseURL}/choose-sub-category/${selectedCategory}`)
        .then(res => res.json())
        .then(data => {
          setSubCategories(data);
        })
        .catch(err => console.log(err));
    }
  }, [selectedCategory]);


  /*
  React.useEffect(() => {
      // set user ip
      fetch('https://geolocation-db.com/json/')
          .then(res => res.json())
          .then(data => {
              setCountryNameFromIp(data.country_name);
              setStateNameFromIp(data.state);
          })
  }, []);

  React.useEffect(() => {
      if (countryNameFromIp) {
          const country = areas.find(area => area.name === countryNameFromIp);
          if (country) {
              setSelectedContinent(country.parent);
              setSelectedCountry(country.id);
              setValue('country', country.id)
              setValue('continent', country.parent)
          }
      }
  }, [countryNameFromIp, areas]);
  */


  React.useEffect(() => {
    if (specificarea) {
      const area = areas.find(area => area.name.toLowerCase() === specificarea.toLowerCase());
      if (area) {
        if (area.type.toLowerCase() === 'city') {
          setSelectedCity(area.id);
          const parent = areas.find(area2 => area2.id === area.parent);
          if (parent) {
            setSelectedState(parent.id);
            setValue('state', parent.id);
            const parent2 = areas.find(area3 => area3.id === parent.parent);
            if (parent2) {
              setSelectedCountry(parent2.id);
              setValue('country', parent2.id);
              const parent3 = areas.find(area4 => area4.id === parent2.parent);
              if (parent3) {
                setSelectedContinent(parent3.id);
                setValue('continent', parent3.id);
              }
            }
          }
        } else if (area.type.toLowerCase() === 'state') {
          setSelectedState(area.id);
          setValue('state', area.id);
          const parent = areas.find(area2 => area2.id === area.parent);
          if (parent) {
            setSelectedCountry(parent.id);
            setValue('country', parent.id);
            const parent2 = areas.find(area3 => area3.id === parent.parent);
            if (parent2) {
              setSelectedContinent(parent2.id);
              setValue('continent', parent2.id);
            }
          }
        } else if (area.type.toLowerCase() === 'country') {
          setSelectedCountry(area.id);
          setValue('country', area.id);
          const parent = areas.find(area2 => area2.id === area.parent);
          if (parent) {
            setSelectedContinent(parent.id);
            setValue('continent', parent.id);
          }
        } else if (area.type.toLowerCase() === 'continent') {
          setSelectedContinent(area.id);
          setValue('continent', area.id);
        }
      }
    }
  }, [specificarea, areas]);


  // React.useEffect(() => {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition((position) => {
  //             const lat = position.coords.latitude;
  //             const lng = position.coords.longitude;
  //             setValue('latitude', lat);
  //             setValue('longitude', lng);
  //         });
  //     } else {
  //         console.log("Geolocation is not supported by this browser.");
  //     }
  // }, []);

  const navigate = useNavigate();

  React.useEffect(() => {
    // check token existance
    if (localStorage.getItem('classified-ads-token') === null) {
      navigate('/login')
    }
  }, [])

  return (
    <div className='min-h-screen w-5/6 mx-auto'>
      <h3 className='py-10 text-2xl font-semibold'>Post your data</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col lg:flex-row mb-14'>
          <div className='bg-gray-100 rounded-lg p-5 lg:p-10 w-full lg:w-[25%] mx-auto'>
            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose continent</h4>
              <select value={selectedContinent} {...register("continent", {
                required: true, onChange: (e) => {
                  setSelectedContinent(e.target.value)
                }
              })} className="select select-accent w-full border border-gray-600">
                <option value="">Select one</option>
                {areas && areas.map((area, index) => {
                  if (area.type === 'continent') {
                    return <option key={index} value={area.id}>{area.name}</option>
                  }
                  return null;
                })}
              </select>
              {errors.continent && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}
            </div>
            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose country</h4>
              <select value={selectedCountry} {...register("country", {
                required: true, onChange: (e) => {
                  setSelectedCountry(e.target.value)
                },
              })} className="select select-accent w-full border border-gray-600">
                <option value="">Select one</option>
                {areas.map(area => {
                  if (area.parent === parseInt(selectedContinent)) {
                    return <option key={area.id} value={area.id}>{area.name}</option>
                  }
                  return null;
                })}

              </select>
              {errors.country && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}
            </div>
            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose state</h4>
              {/* <select {...register("area", { required: true })} className="select select-accent w-full">
                                <option value="">
                                    Select one
                                </option>
                                {areas.map(area => {
                                    if (area.parent === parseInt(selectedCountry)) {
                                        hasArea = true;
                                        return <option key={area.id} value={area.id}>{area.name}</option>
                                    }
                                    return null;
                                })}
                                {!hasArea && areas.map(area => {
                                    if (area.id === parseInt(selectedCountry)) {
                                        return <option key={area.id} value={area.id}>{area.name}</option>
                                    }
                                    return null;
                                })}
                            </select> */}
              <SelectArea areas={areas} selectedCountry={selectedCountry} setAreas={setAreas} register={register}
                          setValue={setValue} setSelectedArea2={setSelectedArea} selectedState={selectedState}/>
              {errors.area && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}
            </div>

            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose city</h4>
              <SelectCity areas={areas} selectedArea={selectedArea} setAreas={setAreas} setValue={setValue}
                          selectedCityParam={selectedCity}/>
              {errors.city && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}
            </div>


            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose Category</h4>
              <select {...register("category", {
                required: true, onChange: (e) => {
                  setSelectedCategory(e.target.value)
                }
              })} className="select select-accent w-full border border-gray-600">
                <option value="">Select one</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
              {errors.category && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}

            </div>
            <div className='pb-5'>
              <h4 className='font-semibold pb-2'>Choose Sub-category</h4>
              <select {...register("sub_category", {required: true})}
                      className="select select-accent w-full border border-gray-600">
                <option value="">Select one</option>
                {subCategories.map(subCategory => (
                  <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                ))}
              </select>
              {errors.sub_category && <span className='text-sm text-red-500 mb-3 italic'>Selection is required</span>}
            </div>
          </div>
          <div className='bg-gray-100 rounded-lg p-5 lg:p-10  w-full mx-auto lg:w-[75%] lg:ml-5 ml-0 mt-10 lg:mt-0  '>
            <label className='block text-gray-700 font-semibold mb-3'>
              Post Title
            </label>
            <input {...register("title", {required: true})} type="text" placeholder="Type your post title here"
                   className="input input-bordered input-accent w-full border border-gray-600"/>
            {errors.title && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}

            <label className='block text-gray-700 font-semibold my-3'>
              Description
            </label>
            <textarea {...register("description", {required: true})} rows="4" cols="50"
                      className="textarea textarea-accent w-full border border-gray-600"
                      placeholder="Post description goes here"></textarea>
            {errors.description && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}


            <div className='flex flex-col lg:flex-row lg:justify-between'>
              <div className='w-full lg:w-[49%]'>
                <label className='block text-gray-700 font-semibold my-3'>
                  Email
                </label>
                <input {...register("email", {required: true})} type="email" placeholder="Type your email here"
                       className="input input-bordered input-accent w-full border border-gray-600"/>
                {errors.email && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}
              </div>
              <div className='w-full lg:w-[49%]'>
                <label className='block text-gray-700 font-semibold my-3'>
                  Phone
                </label>
                <input {...register("phone", {required: true})} type="tel" placeholder="Type your phone number here"
                       className="input input-bordered input-accent w-full border border-gray-600"/>
                {errors.phone && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}
              </div>
            </div>

            <div>
              <label className='block text-gray-700 font-semibold my-3'>
                Street
              </label>
              <textarea {...register("street", {required: true})}
                        className="textarea textarea-accent w-full border border-gray-600"
                        placeholder="Your street name"></textarea>
              {errors.street && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}
            </div>

            <div className='flex flex-col lg:flex-row lg:justify-between'>
              <div className='w-full lg:w-[49%]'>
                <label className='block text-gray-700 font-semibold my-3'>
                  Upload Image (Optional)
                </label>
                <div className=''>
                  <input {...register("image")} type="file"
                         className="input input-bordered input-accent w-full border border-gray-600 py-1"
                  />
                </div>
              </div>
              <div className='w-full lg:w-[49%]'>
                <label className='block text-gray-700 font-semibold my-3'>
                  Postal code
                </label>
                <input {...register("postal_code", {required: true})} type="text" placeholder="Type your postal code"
                       className="input  input-bordered input-accent w-full border border-gray-600"/>
                {errors.postal_code && <span className='text-sm text-red-500 mb-3 italic'>This field is required</span>}
              </div>
            </div>

            <div className='flex justify-end mt-10'>
              {loading ? <button type='button'
                                 className='px-7 w-full py-2 bg-[#FFCC40] rounded text-black'>Loading...</button> : (
                <button type='submit' className='px-7 w-full py-2 bg-[#FFCC40] rounded text-black'>Post</button>
              )}

            </div>
          </div>
        </div>
      </form>


    </div>
  );
};

export default AdPost;