import React from 'react'

export default function AboutUs() {
  return (
    <div className='md:w-9/12 mx-auto mt-5 mb-16 px-2'>
        <h1 className='text-2xl font-bold mb-2'>About Us</h1>
        <div>
            sinformation is still not readily accessible to a less 
            accomplished public. While talent could be found in hidden deep 
            pocketed regions, the lack of information to such talent becomes a 
            bottle neck in their development. We at CROSSLINKER are pledged to 
            bring the deeply hidden information to hard-to-reach people and places. 
            Consider CROSSLINKER as a philanthropist company that would like to 
            help those who wanted to make a positive contribution and impact to 
            the human society.  The concept of CROSSLINKER was designed by a 
            high school student with the vision to make this data driving world 
            safer & secured that offers life transforming information accessible 
            to every part of the globe. Too much information creates confusion 
            and therefore having precise information will be critical. We 
            therefore call CROSSLINKER – a <b>precision technology™</b> company.
        </div>
    </div>
  )
}
