import React from 'react'

export default function ChooseContinent({selectedContinent, setSelectedContinent, allCountries, setSelectedCountry}) {
  return (
    <div className='pb-3'>
        <h4 className='font-semibold pb-2'>Choose continent</h4>
        <select onChange={(e) => {setSelectedContinent(e.target.value); setSelectedCountry('')}} value={selectedContinent} className="select select-accent w-full border border-gray-600">
            <option>Select one</option>
            {allCountries && allCountries.map((continent, index) => {
                if (continent.type === 'continent') {
                    return <option key={index} value={continent.id}>{continent.name}</option>
                }
                return null;
            })}
        </select>
    </div>
  )
}
