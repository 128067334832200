import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { baseURL } from '../../components/BaseURL';
import ChooseArea from './ChooseArea';
import ChooseCategory from './ChooseCategory';
import ChooseCity from './ChooseCity';
import ChooseContinent from './ChooseContinent';
import ChooseCountry from './ChooseCountry';
import ChooseSubCategory from './ChooseSubCategory';
import { UserContext } from '../../App';
import Swal from 'sweetalert2';


const ClassifiedAds = () => {
    const navigate = useNavigate();
    const userData = useContext(UserContext);

    const [allCountries, setAllCountries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedContinent, setSelectedContinent] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [countryName, setCountryName] = useState('');
    const [subCategoryName, setSubCategoryName] = useState('');
    const [loadingAds, setLoadingAds] = useState(true);
    const [sortBy, setSortBy] = useState('');
    const [counter, setCounter] = useState(0);
    const [allAds, setAllAds] = useState([]);
    const [countryNameFromIp, setCountryNameFromIp] = useState('');


    useEffect(() => {
        const backToTop = () => {
            window.scrollTo({
                top: 0
            });
        }
        backToTop();
    }, []);

    const { country, adcategory, dateParam } = useParams();

    const [isToday, setIsToday] = useState(false);
    useEffect(() => {
        const date = new Date(dateParam);
        if (date.getDate() === new Date().getDate()) {
            setIsToday(true);
        } else {
            setIsToday(false);
        }
    }, [dateParam]);

    const [ads, setAds] = useState([]);
    const fetchAds = useRef(true)


    useEffect(() => {
        if (fetchAds.current) {
            fetchAds.current = false;
            fetch(`${baseURL}/get-ads/${country}/${adcategory}/${dateParam}`)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    setAds(data);
                    setLoadingAds(false);
                    setSortBy('newest');
                    setAllAds(data);
                })
                .catch(err => console.log(err));
        }
    }, [country, adcategory, dateParam]);


    useEffect(() => {
        fetch(`${baseURL}/get-all-areas`)
            .then((res) => res.json())
            .then((data) => {
                setAllCountries(data);
            })
            .catch((err) => console.log(err));
    }, []);

    React.useEffect(() => {
        fetch(`${baseURL}/get-all-categories`)
            .then(res => res.json())
            .then(data => {
                setCategories(data);
            })
            .catch(err => console.log(err));

    }, []);

    React.useEffect(() => {
        if (selectedArea && selectedSubCategory) {
            if (selectedCity) {
                allCountries.map((country, index) => {
                    if (country.id === parseInt(selectedCity)) {
                        setCountryName(country.name);
                    }
                    return null;
                });
            } else {
                allCountries.map((country, index) => {
                    if (country.id === parseInt(selectedArea)) {
                        setCountryName(country.name);
                    }
                    return null;
                });
            }
            categories.map((category, index) => {
                if (category.id === parseInt(selectedSubCategory)) {
                    setSubCategoryName(category.name);
                }
                return null;
            });
        }
    }, [selectedArea, selectedSubCategory, allCountries, categories, selectedCity]);

    React.useEffect(() => {
        // sort
        if (sortBy === 'newest') {
            const sortedAds = ads.sort((a, b) => {
                return b.id - a.id;
            })
            setAds(sortedAds);
        } else {
            const sortedAds = ads.sort((a, b) => {
                return a.id - b.id;
            })
            setAds(sortedAds);
        }
        // force re render after sort
        setCounter(counter + 1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, ads]);


    const handleUpdateAds = () => {
        if (!selectedContinent) {
            alert('Please select continent');
            return;
        } else if (!selectedCountry) {
            alert('Please select country');
            return;
        } else if (!selectedArea) {
            alert('Please select area');
            return;
        } else if (!selectedCategory) {
            alert('Please select category');
            return;
        } else if (!selectedSubCategory) {
            alert('Please select sub category');
            return;
        }

        if (country !== countryName || adcategory !== subCategoryName) {
            setLoadingAds(true);
            setAds([]);
            const date = new Date();
            let dateString = date.toLocaleDateString();
            let newDate = dateString.split('/')
            // newDate formate yyyy-mm-dd
            newDate = newDate[2] + '-' + newDate[0] + '-' + newDate[1];
            navigate(`/all-ads/${countryName}/${subCategoryName}/${dateParam}`);
            fetchAds.current = true;
        }
    }

    const handleSearch = (e) => {
        // search for data
        const search = e.target.value;
        const filteredAds = allAds.filter(ad => {
            return ad.title.toLowerCase().includes(search.toLowerCase());
        })
        setAds(filteredAds);

    }

    React.useEffect(() => {
        // set user ip
        fetch('https://geolocation-db.com/json/')
            .then(res => res.json())
            .then(data => {
                setCountryNameFromIp(data.country_name);
            })
    }, []);

    React.useEffect(() => {
        if (countryNameFromIp) {
            const country = allCountries.find(area => area.name === countryNameFromIp);
            if (country) {
                setSelectedContinent(country.parent);
                setSelectedCountry(country.id);
            }
        }
    }, [countryNameFromIp, allCountries]);


    return (
        <div className='min-h-screen w-5/6 flex flex-col lg:flex-row mx-auto mb-20'>

            {/* ---------------SIDEBAR------------ */}
            <div className='w-full lg:w-[25%] mx-auto mt-14 bg-gray-50 p-5 lg:p-7 rounded-lg'>

                <ChooseContinent selectedContinent={selectedContinent} setSelectedContinent={setSelectedContinent} allCountries={allCountries} setSelectedCountry={setSelectedCountry} />
                <ChooseCountry allCountries={allCountries} selectedContinent={selectedContinent} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} setSelectedArea={setSelectedArea} />
                <ChooseArea allCountries={allCountries} selectedCountry={selectedCountry} selectedArea={selectedArea} setSelectedArea={setSelectedArea} />
                <ChooseCity allCountries={allCountries} selectedCountry={selectedCountry} selectedArea={selectedArea} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
                <ChooseCategory categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} setSelectedSubCategory={setSelectedSubCategory} />
                <ChooseSubCategory categories={categories} selectedCategory={selectedCategory} selectedSubCategory={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory} />
                <div>
                    <button onClick={handleUpdateAds} className='bg-gray-500 text-white rounded px-5 w-full py-3'>Update data</button>
                </div>
            </div>

            {/* ----------ADS--------- */}
            <div className='w-full lg:w-[75%] mx-auto bg-gray-50 p-7 rounded-lg mt-14 lg:ml-4'>
                <div className='flex flex-col lg:flex-row items-center mt-7'>


                    {/* --------------SEARCH BAR-------------- */}
                    <div className="input-group w-full lg:w-[70%] lg:mr-5 mx-auto mb-5 lg:mb-0">
                        <span><i className="bi bi-search"></i></span>
                        <input onChange={handleSearch} type="text" placeholder="Search for data" className="input input-bordered w-full" />
                    </div>


                    <div className='w-full lg:w-[30%] mx-auto'>
                        <select onChange={(e) => { setSortBy(e.target.value) }} value={sortBy} className="select select-accent w-full  border border-gray-600">
                            <option value="">Sort by</option>
                            <option value="newest">Latest</option>
                            <option value="oldest">Oldest</option>
                        </select>
                    </div>
                </div>


                <div className='mt-5'>
                    <h3 className='py-2 text-2xl font-semibold'>
                    <span className='text-gray-500'>Showing data for subcategory:</span> <span className='text-gray-900 hover:text-gray-600  '>"{adcategory}"</span>
                        {!isToday && <span className='text-gray-500 hover:text-gray-800'> on {dateParam}</span>}
                    </h3>
                    <h4>
                    <span className='text-gray-500'>Total data found:</span>
                        {loadingAds ? <span> Loading...</span> : <span className='text-gray-900 hover:text-gray-600  '> {ads.length}</span>}
                    </h4>
                </div>




                <div className='pt-10 mb-20'>
                    {
                        ads.map((ad, index) => {
                            let dateString = ''
                            if (ad.description.includes('Award Year : ')){
                                dateString = ad.description.split('Award Year : ')[1].split('\n')[0]
                            }
                            if (ad.is_premium) {
                                return (
                                    <div key={index} onClick={() => {
                                        if (userData.userData.subscription_type === 'paid'){
                                            navigate(`/data/${ad.id}`)
                                        } else {
                                            Swal.fire({
                                                title: 'You need to subscribe to view premium data',
                                                text: "Do you want to subscribe now?",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes, subscribe now!'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    navigate('/upgrade')
                                                }
                                            })
                                        }
                                    }}>
                                        <div className='flex justify-between w-full bg-white items-center pr-4 hover:bg-gray-200'>
                                            <h3 className=' cursor-pointer text-gray-900 hover:text-gray-600 p-3  rounded mb-2 lg:pl-7 py-3 '>
                                                <span className='text-gray-500 mr-5'> &#128970;  {dateString}</span> {ad.title}
                                            </h3>
                                            <div>&#128274;</div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index}>
                                        <Link to={`/data/${ad.id}`} >
                                            <div className='flex justify-between w-full bg-white hover:bg-gray-200'>
                                                <h3 className=' cursor-pointer text-gray-900 hover:text-gray-600 p-3  rounded mb-2 lg:pl-7 py-3 '>
                                                    <span className='text-gray-500 mr-5'> &#128970;  {dateString}</span> {ad.title}
                                                </h3>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        })
                    }


                </div>
            </div>
        </div >
    );
};

export default ClassifiedAds;