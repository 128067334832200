import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function NoDate() {
    const navigate = useNavigate();
    const { country, adcategory } = useParams();
    const date = new Date()
    let dateString = date.toLocaleDateString();
    let newDate = dateString.split('/')
    newDate = newDate[2] + '-' + newDate[0] + '-' + newDate[1];
    return navigate(`/all-ads/${country}/${adcategory}/${newDate}`)
}
