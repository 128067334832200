
import { useEffect, useState, createContext } from "react";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import AdCateory from "./pages/AdCategory/AdCateory";
import AdPost from "./pages/AdPost/AdPost";
import Blog from "./pages/Blog/Blog";
import ClassifiedAds from "./pages/ClassifiedAds/ClassifiedAds";
import NoDate from "./pages/ClassifiedAds/NoDate";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import MyAccount from "./pages/MyAccount/MyAccount";
import NoAds from "./pages/NoAds/NoAds";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ForgetPassword from "./pages/Register/ForgetPassword";
import Register from "./pages/Register/Register";
import VerifyEmail from "./pages/Register/VerifyEmail";
import ChartDetails from "./pages/SingleAd/ChartDetails";
import SingleAd from "./pages/SingleAd/SingleAd";
import DirectSingleAd from "./pages/SingleAd/DirectSingleAd";
import Upgrade from "./pages/Upgrade/Upgrade";
import Profile from "./pages/userProfile/Profile";
import {baseURL} from "./components/BaseURL";
import TermsAndCondition from "./pages/Footer/TermsAndCondition";
import AboutUs from "./pages/Footer/AboutUs";
import Jobs from "./pages/Footer/Jobs";
import ContactUs from "./pages/Footer/ContactUs";
import AdWithContinent from "./pages/AdContinent/AdWithContinent";

export const UserContext = createContext();

function App() {

  const [userData, setUserData] = useState(null);

  useEffect(() => {
      fetch(baseURL + '/get-profile-data', {
          method: 'GET',
          headers: {
              'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
              'Content-Type': 'application/json'
          }
      })
          .then(res => res.json())
          .then(data => {
              setUserData(data);

          }).catch(err => {
              console.log(err)
          }
          )
  }, [])


  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      <div className="App">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/all-ads/:country" element={<AdCateory />} />
            <Route path="/post/:specificarea" element={<AdPost />} />
            <Route path="/all-ads/:country/:adcategory" element={<NoDate />} />
            <Route path="/ad-continent/:query/:continent" element={<AdWithContinent />}/>
            <Route path="/all-ads/:country/:adcategory/:dateParam" element={<ClassifiedAds />} />
            <Route path="/all-ads/:country/:adcategory/:dateParam/:singlead" element={<SingleAd />} />
            <Route path="/data/:singlead" element={<DirectSingleAd />} />
            <Route path="/no-ads-found" element={<NoAds />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsAndCondition />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/chart-details/:id" element={<ChartDetails />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </UserContext.Provider>
  );
}

export default App;
