import React from 'react'

export default function ChooseSubCategory({categories, selectedCategory, selectedSubCategory, setSelectedSubCategory}) {

    return (
    <div className='pb-5'>
        <h4 className='font-semibold pb-2'>Choose subcategory</h4>
        <select onChange={(e)=> setSelectedSubCategory(e.target.value)} className="select select-accent w-full  border border-gray-600">
            <option value={selectedSubCategory}>Select one</option>
            {categories && categories.map((category, index) => {
                if (category.parent === parseInt(selectedCategory)) {
                    return <option key={index} value={category.id}>{category.name}</option>
                }
                return null;
            })}
        </select>
    </div>
  )
}
