import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseURL } from '../../components/BaseURL';

const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        fetch(`${baseURL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
            })
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.token) {
                    localStorage.setItem('classified-ads-token', data.token);
                    window.location.href = '/';
                    Swal.fire({
                        title: 'Success!',
                        text: 'You have successfully logged in!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (data.error) {
                    setError(data.error);
                } else {
                    setError('Something went wrong! Please try again.');
                }
            })
    }


    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto flex justify-center items-center">

                <div className="w-11/12 lg:w-2/6 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col mt-10 md:mt-0">
                    <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Login</h2>
                    <div className='text-red-500'>
                        {error && <li>{error}</li>}
                    </div>
                    <div className="relative mb-4">
                        <label for="full-name" className="leading-7 text-sm text-gray-600">Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} type="text" id="full-name" name="full-name" placeholder='Type your email' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    <div className="relative mb-4">
                        <label for="password" className="leading-7 text-sm text-gray-600">Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" name="password" placeholder='Type password' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                    </div>
                    {loading ? (
                        <button className="btn loading bg-[#FFCC40] text-black">Loading...</button>
                    ) : (
                        <button onClick={handleSubmit} className="bg-[#FFCC40] border-0 py-2 px-8 focus:outline-none rounded text-lg hover:bg-[#FFCC40] text-black">Login</button>
                    )}

                    <p className="text-xs mt-3">Don't have an account? <Link to="/register" className="underline text-gray-500" >Sign up</Link> here.</p>

                    <p>
                        <Link to="/forget-password" className="text-sm underline mt-2 text-gray-500" >Forgot password?</Link>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Login;