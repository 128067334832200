import React from "react";

export default function ChooseCountry({allCountries, selectedContinent, selectedCountry, setSelectedCountry, setSelectedArea}) {
    

    return (
        <div className="pb-3">
            <h4 className="font-semibold pb-2">Choose country</h4>
            <select onChange={(e) => {setSelectedCountry(e.target.value); setSelectedArea('')}} value={selectedCountry} className="select select-accent w-full  border border-gray-600">
                <option value="">Select one</option>
                {allCountries &&
                    allCountries.map((country, index) => {
                        //console.log('parent-'+country.parent, 'selectedContinent-'+selectedContinent);
                        if (country.parent === parseInt(selectedContinent)) {
                            return (
                                <option
                                    key={index}
                                    value={country.id}
                                >
                                    {country.name}
                                </option>
                            );
                        } else {
                            return null;
                        }
                    })}
            </select>
        </div>
    );
}
