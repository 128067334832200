import {Link, useNavigate, useParams} from "react-router-dom";
import {baseURL} from "../../components/BaseURL";
import React, {useContext, useEffect, useState} from "react";
import Swal from "sweetalert2";
import {UserContext} from "../../App";
import PieChart from "../../components/PieChart";
import BarChartComponent from "../../components/BarChart";

const AdWithContinent = () => {
  const navigate = useNavigate();
  const userData = useContext(UserContext);

  let {continent,query}= useParams();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noAds, setNoAds] = useState(false);
  const [paidSubscription, setPaidSubscription] = useState(false);
  const [visible, setVisible] = useState(false);
  const [continentData, setContinentData] = useState([]);
  const [clickContinent, setClickContinent] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [inputPageValue, setInputPageValue] = useState("")
  const [isPrev, setIsPrev] = useState(false)
  const [isNext, setIsNext] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [sortBy, setSortBy] = useState("award_year")
  const [continentValue, setContinentValue] = useState(continent)


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(baseURL + '/get-profile-data', {
          method: 'GET',
          headers: {
            Authorization: 'Token ' + localStorage.getItem('classified-ads-token'),
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.subscription_type === 'paid') {
          setPaidSubscription(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const getContinentData = async () => {
    fetch(`${baseURL}/v1/ads-continents/${query}/`)
      .then(res=>res.json())
      .then(data => {
        setLoading(true)
        setContinentData(data["continent"])
        setLoading(false)
      })
  }

  const getAdsV3 = async (continent, query, pageNo, sort_by) => {
    setNoAds(false)
    if (query.length > 2 && continent.length > 2)
    {
      if (continent==='Asia/Pacific/Middle East') continent = 'Asia'

      fetch(`${baseURL}/v3/search-ads/${continent}/${query}/${pageNo}?sort_by=${sort_by}`)
        .then(res=>res.json())
        .then(data => {
          setResults(data['ads'])
          if (data['ads'].length <= 0) setNoAds(true)
          setActivePage(pageNo)
          setTotalPage(data['total_pages'])
          setIsNext(data['next'])
          setIsPrev(data['previous'])
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
          setErrorMessage("Something went wrong. Please try again later.")
        })
    } else {
      // setResults([])
    }
  }
  const handleNumberButtonClick = (event) => {
    if (activePage === parseInt(event.target.textContent, 10)) return null;
    // setResults([])
    setLoading(true)
    if (clickContinent) {
      getAdsV3(clickContinent, query, parseInt(event.target.textContent, 10), sortBy)
    } else {
      getAdsV3(continentValue, query, parseInt(event.target.textContent, 10), sortBy)
    }
  }

  const handleNextButtonClick = async () => {
    setLoading(true)
    if (clickContinent) {
      getAdsV3(clickContinent, query, activePage + 1, sortBy)
    } else {
      getAdsV3(continentValue, query, activePage + 1, sortBy)
    }
  }

  const handlePrevButtonClick = async () => {
    if (activePage > 1 )
    {
      // setResults([])
      setLoading(true)
      if (clickContinent) {
        getAdsV3(clickContinent, query, activePage - 1, sortBy)
      } else {
        getAdsV3(continentValue, query, activePage - 1, sortBy)
      }
    }
    return null
  }

  const handlePageNoSearch = async () => {
    // setResults([])
    setLoading(true)
    setInputPageValue("")
    if (clickContinent) {
      getAdsV3(clickContinent, query, inputPageValue, sortBy)
    } else {
      getAdsV3(continentValue, query, inputPageValue, sortBy)
    }
  }

  const handleChangePageInput = event => {
    if (event.target.value.length === 0)
    {
      setInputPageValue("")
      return
    }
    const value = Math.max(1, Math.min(totalPage, Number(event.target.value)));
    setInputPageValue(value);
  };



  useEffect(() => {
    getAdsV3(continentValue, query, 1, sortBy)
    getContinentData()
  }, [query]);

  const handleBarClick = (clickData) => {
    setLoading(true);
    setClickContinent(clickData['name'])
    setContinentValue(continent)
    getAdsV3(clickData['name'], query, 1, sortBy)
  }
  const handleSortByChange = (event) => {
    if (event.target.value !== sortBy){
      setSortBy(event.target.value);
      setLoading(true);
      getAdsV3(continentValue, query, 1, event.target.value)
    }
  };

  function pagination() {
    if (totalPage <=5)
    {
      return (
        <>
          {
            isPrev ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            ) : null
          }
          {
            Array.from({ length: totalPage }, (_, index) => (
              <button
                key={index + 1}
                className={`paginationButton join-item btn btn-sm ${index + 1 === activePage ? 'paginationButtonActive' : ''}`}
                onClick={handleNumberButtonClick}
              >
                {index + 1}
              </button>
            ))
          }
          {
            isNext ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
            ) : null
          }
        </>
      )
    } else {
      if (activePage < 5)
      {
        return (
          <>
            {
              isPrev ? (
                <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
              ) : null
            }
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${
                    index + 1 === activePage ? 'paginationButtonActive' : ''
                  }`}
                  onClick={handleNumberButtonClick}
                >
                  {index + 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 <= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${activePage + index - 1  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {activePage + index - 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 >= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${totalPage-5+1+index  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {totalPage-5+1+index}
                </button>
              ))
            }
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      }
    }
  }



  return (
    <>
      {paidSubscription ? (
        <></>
      ) : (
        <>
          {visible ? (
            <div className="flex justify-center mt-5">
              <Link to="/upgrade">
                <button
                  className="animate-pulse text-xs px-4 py-1 bg-opacity-20 text-black text-center rounded-full bg-[#FFCC40]">
                  Upgrade to pro & get premium data
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {
        loading ? <></> :
          <>
            <h4 className="text-2xl font-semibold py-4 text-center">
              Your data for {query} in {clickContinent ? <>{clickContinent}</> : <>{continentValue}</> }
            </h4>
          </>
      }
      {continentData.length !== 0 ? (
        <div className="my-10 w-3/3 lg:w-3/4 mx-auto">
          <BarChartComponent inputData={continentData} clickFunction={handleBarClick}/>
        </div>
      ) : null }

      <div className="my-10 w-5/6 lg:w-1/2 mx-auto">
        {
          !loading ? (
            <div className="mb-2">
              <div className="flex flex-row justify-end items-center">
                <div className="form-control mr-2">
                  <label className="label cursor-pointer">
                    <span className="label-text font-bold mr-2">Sort By </span>
                    <select
                      value={sortBy}
                      onChange={handleSortByChange}
                      className="select select-warning hover:outline-0 focus:outline-0"
                    >
                      <option value="award_year">Award Year</option>
                      <option value="alphabet">Alphabet</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          ) : null
        }
        {results.map((result, index) => {
          const description = result.description;
          let awardYear = '';
          if (description.includes('Award Year : ')) {
            awardYear = description.split('Award Year : ')[1].split('\n')[0];
          } else if (description.includes('Year : ')) {
            awardYear = description.split('Year : ')[1].split('\n')[0];
          }

          return (
            <div
              key={index}
              onClick={() => {
                if (result.is_premium) {
                  if (userData.userData.subscription_type === 'paid') {
                    navigate(`/data/${result.id}`);
                  } else {
                    Swal.fire({
                      title: 'You need to subscribe to view premium data',
                      text: 'Do you want to subscribe now?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, subscribe now!',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate('/upgrade');
                      }
                    });
                  }
                } else {
                  navigate(`/data/${result.id}`);
                }
              }}
            >
              <div
                className={`flex justify-between items-center bg-gray-100 pr-4 hover:bg-gray-300 transition duration-100  rounded mb-2 lg:pl-7 py-3  cursor-pointer text-gray-500 hover:text-gray-800 p-3 ${
                  result.is_premium ? 'cursor-pointer' : ''
                }`}
              >
                <h3>
                  <span className="text-gray-500 mr-5">{awardYear}</span>
                  {result.title}
                </h3>
                {result.is_premium ? <div>&#128274;</div> : null}
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-center">
        {loading ? <div>Loading...</div> : null}
        {noAds ? <div>No data found</div> : null}
        {errorMessage ? <div>{errorMessage}</div> : null}
        {
          results.length > 0 ?
            <>
              <div className="join">
                {
                  pagination()
                }
              </div>
            </> : null
        }
      </div>
      <br/>
    </>
  )
}
export default AdWithContinent