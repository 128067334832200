import React, { useState } from 'react'
import LineChart from './LineChart'

export default function CharContainer({data, adId}) {
    const [byProgramAndPhase, setByProgramAndPhase] = useState({})
    const [byYear, setByYear] = useState({})
    const [byAgency, setByAgency] = useState({})

      React.useEffect(() => {
        const sampleDataStructure = {
            labels: [],
            datasets: [
              {
                  type: 'bar',
                  label: 'Award Count',
                  data: []
              }, {
                  type: 'line',
                  label: 'Award Amount',
                  data: [],
              }
          
            ]
          }
        if (data?.data?.byProgramAndPhase?.labels) {
            let byProgramAndPhase = JSON.parse(JSON.stringify(sampleDataStructure))
            byProgramAndPhase.labels = data.data.byProgramAndPhase.labels
            byProgramAndPhase.datasets[0].data = data.data.byProgramAndPhase.count.map((item) => item)
            byProgramAndPhase.datasets[1].data = data.data.byProgramAndPhase.amount.map((item) => item)
            setByProgramAndPhase(byProgramAndPhase)
        }

        if (data?.data?.byYear?.labels) {
            let byYear = JSON.parse(JSON.stringify(sampleDataStructure))
            byYear.labels = data.data.byYear.labels
            byYear.datasets[0].data = data.data.byYear.count
            byYear.datasets[1].data = data.data.byYear.amount
            setByYear(byYear)
        }

        if (data?.data?.byAgency?.labels) {
            let byAgency = JSON.parse(JSON.stringify(sampleDataStructure))
            byAgency.labels = data.data.byAgency.labels
            byAgency.datasets[0].data = data.data.byAgency.count
            byAgency.datasets[1].data = data.data.byAgency.amount
            setByAgency(byAgency)
        }
    }, [data])


    return (
        <div>
            <h3 className='mb-3'><span className='bg-gray-700 text-white rounded px-3 py-1'>By Program and Phase</span></h3>
            {byProgramAndPhase.labels && <LineChart adId={adId} data={byProgramAndPhase} />}
            <br/><br/>
            <h3 className='mb-3'><span className='bg-gray-700 text-white rounded px-3 py-1'>By Year</span></h3>
            {byYear.labels && <LineChart adId={adId} data={byYear} />}
            <br/><br/>
            <h3 className='mb-3'><span className='bg-gray-700 text-white rounded px-3 py-1'>By Agency</span></h3>
            {byAgency.labels && <LineChart adId={adId} data={byAgency} />}
        </div>
    )
}
