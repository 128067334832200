import React, { useState } from "react";
import { Link } from "react-router-dom";
import Country from "./Country";

export default function Continent({ allCountries, continent }) {

    // check if mobile device
    const isMobile = window.innerWidth < 768;


    const [hideCountry, setHideCountry] = useState(true);

    const toogleHideCountry = (id) => {
        setHideCountry(!hideCountry);
    };

    return (
        <div key={continent.id} className="" id={continent.id}>
            <div
                className=" cursor-pointer"
                onClick={() => {
                    toogleHideCountry(continent.id);
                }}
            >
                <div className="flex justify-between items-center pt-3">
                    <div>
                        <h3>
                            <span className="text-xl lg:text-2xl  font-semibold">
                                {continent.name}
                            </span>
                        </h3>
                    </div>
                    <div>
                        {
                            hideCountry ? (
                                <i className="bi bi-plus-square text-2xl text-gray-500"></i>
                            ) : (
                                <i className="bi bi-dash-square text-2xl text-gray-500"></i>
                            )
                        }
                    </div>

                </div>
                <hr className="border-gray-300 mt-4" />
            </div>

            {/* -------COUNTRY LOOP HERE------ */}
            {hideCountry ? null : (
                <div className="mb-10 mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {allCountries &&
                        allCountries.map((country, index) => {
                            if (
                                //country.type === "country" &&
                                country.parent === continent.id
                            ) {
                                return (
                                    <Country key={country.id} country={country} allCountries={allCountries} />
                                );
                            } else {
                                return null;
                            }
                        })}
                </div>
            )}
        </div>
    );
}
