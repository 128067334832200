import React from 'react';

const NoAds = () => {
    return (
        <div className='min-h-screen  w-5/6  lg:w-2/3 mx-auto'>
            <h3 className='py-14 text-2xl font-semibold text-center'>No ads found for this category</h3>
        </div>
    );
};

export default NoAds;