import React, {Fragment, useEffect, useState} from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { baseURL } from '../BaseURL';




export default function EditProfile({ isOpen, closeModal, userData, setUserData }) {

    // SEND DATA TO SERVER
    const [firstName, setFirstName] = useState(userData.first_name || '');
    const [email, setEmail] = useState(userData.email || '');
    const [phone, setPhone] = useState(userData.phone || '');
    const [address, setAddress] = useState(userData.address || '');

    useEffect(() => {
        setFirstName(userData.first_name || '');
        setEmail(userData.email || '');
        setPhone(userData.phone || '');
        setAddress(userData.address || '');
    }, [userData]);
    const changeAction = (e) => {
        e.preventDefault();
        fetch(baseURL + '/edit-profile', {
            method: 'POST',
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                first_name: firstName,
                email: email,
                phone: phone,
                address: address,
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 'success') {
                    setUserData({
                        ...userData,
                        first_name: firstName,
                        email: email,
                        phone: phone,
                        address: address,
                    });
                    closeModal();
                } else {
                    alert('Email already exists');
                }
                console.log(data);
            }).catch(err => {
                alert('Something went wrong');
                console.log(err);
            }).finally(() => {
                closeModal();
            })
    }
    const closeButton = (e) => {
        e.preventDefault()
        closeModal()
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Edit profile
                                    </Dialog.Title>

                                    <div className="mt-2">
                                        <form action="">
                                            <div className='flex flex-col my-1'>
                                                <label htmlFor="">Name: </label>
                                                {/*<input onChange={*/}
                                                {/*    (e) => {*/}
                                                {/*        userData && setUserData({ ...userData, first_name: e.target.value })*/}
                                                {/*        // setName(e.target.value)*/}
                                                {/*    }*/}
                                                {/*} value={(userData && userData.first_name) || ""} type="text" className='px-3 py-2 border-none outline-none bg-gray-100 rounded' placeholder='name' />*/}
                                                <input
                                                  onChange={(e) => setFirstName(e.target.value)}
                                                  value={firstName}
                                                  type="text"
                                                  className='px-3 py-2 border-none outline-none bg-gray-100 rounded'
                                                  placeholder='name'
                                                />
                                            </div>
                                            <div className='flex flex-col my-1'>
                                                <label htmlFor="">Email: </label>
                                                <input readOnly
                                                    // onChange={
                                                    //     (e) => {
                                                    //         userData && setUserData({ ...userData, email: e.target.value })
                                                    //     }
                                                    // } 
                                                    value={email} type="email" className='px-3 py-2 border-none outline-none bg-gray-100 rounded' placeholder='name' />
                                            </div>
                                            <div className='flex flex-col my-1'>
                                                <label htmlFor="">Phone: </label>
                                                {/*<input onChange={*/}
                                                {/*    (e) => {*/}
                                                {/*        userData && setUserData({ ...userData, phone: e.target.value })*/}
                                                {/*    }*/}
                                                {/*} value={phone} type="tel" className='px-3 py-2 border-none outline-none bg-gray-100 rounded' placeholder='phone' />*/}
                                                <input
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  value={phone}
                                                  type="text"
                                                  className='px-3 py-2 border-none outline-none bg-gray-100 rounded'
                                                  placeholder='phone'
                                                />
                                            </div>
                                            <div className='flex flex-col my-1'>
                                                <label htmlFor="">Address: </label>
                                                {/*<textarea onChange={*/}
                                                {/*    (e) => {*/}
                                                {/*        userData && setUserData({ ...userData, address: e.target.value })*/}
                                                {/*    }*/}
                                                {/*} value={address} type="text" className='px-3 py-2 border-none outline-none bg-gray-100 rounded' placeholder='address' />*/}
                                                <input
                                                  onChange={(e) => setAddress(e.target.value)}
                                                  value={address}
                                                  type="text"
                                                  className='px-3 py-2 border-none outline-none bg-gray-100 rounded'
                                                  placeholder='address'
                                                />
                                            </div>
                                        </form>
                                    </div>

                                    <div className='my-2 flex'>
                                        <button onClick={changeAction} className='bg-sky-500 rounded text-white px-5 py-2'>Save changes</button>
                                        <button onClick={closeButton} className='bg-red-500 mx-2 rounded text-white px-5 py-2'>Cancel</button>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
