import React from 'react'
import { Link } from 'react-router-dom';
import { baseURL } from '../../components/BaseURL';




export default function Profile() {
    const [userData, setUserData] = React.useState({});

    React.useEffect(() => {
        fetch(baseURL + '/get-profile-data', {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setUserData(data);
            }).catch(err => {
                console.log(err)
            }
            )
    }, [])

    if (Object.keys(userData).length === 0) {
        return (
            <div className='p-20 text-center font-bold min-h-screen text-xl'>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <div>
            <h1>Profile page under development</h1>
            <img src={userData.image ? 'userData.image' : 'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg'} alt="profile" />
            <h2>Name: {`${userData.first_name} ${userData.last_name}`}</h2>
            <h4>
                Email: {userData.email}

            </h4>
            <h4>Phone: {userData.phone}</h4>
            <h4>Address: {userData.address}</h4>
            <hr />
            <h2>Your Data:</h2>
            <ul>
                {userData && userData.ads.map(ad => (
                    <Link to={`/all-ads/${ad.area.name}/${ad.sub_category.name}/${ad.id}`} key={ad.id}>
                        <li className='text-blue-400'>{ad.title}</li>
                    </Link>
                ))}
            </ul>
        </div>
    )
}
