import React, {useState} from 'react'
import Swal from 'sweetalert2'
import { baseURL } from '../../components/BaseURL';
import {useNavigate} from "react-router-dom";

export default function ForgetPassword() {
    const [email, setEmail] = React.useState('');
    const [otpSent, setOtpSent] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [error, setError] = useState("")
    const navigate = useNavigate();

    const sendOtp = () => {
        fetch(`${baseURL}/auth/forget-password-send-otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.otp_sent) {
                    setOtpSent(true);
                } else {
                    // alert(data.error);
                    setError(data.error)
                }
            })
    }


    const resetPassword = () => {
        if (error) {
          setError("")
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        fetch(`${baseURL}/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                otp,
                password
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.password_reset) {
                  navigate('/login');
                  Swal.fire({
                    title: 'Success!',
                    text: 'You have successfully logged in!',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                  })
                } else {
                    setError(data.error);
                }
            })
    }




    if (otpSent) {
        return (
            <div className='w-5/6 lg:w-1/3 mx-auto min-h-screen pt-24 pb-32 '>
                <h1 className='text-2xl font-semibold text-center py-7'>Reset Password</h1>
                {
                  error ? (<p className=" text-error">{error}</p>) : null
                }
                <div className='flex flex-col'>
                    <label className='py-3'>Enter OTP</label>
                    <input type="text" className='bg-gray-100 rounded py-2 outline-none px-4' value={otp} onChange={(e) => setOtp(e.target.value)} />
                </div>
                <div className='flex flex-col'>
                    <label className='py-3'>Password</label>
                    <input type="password" className='bg-gray-100 rounded py-2 outline-none px-4' value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className='flex flex-col'>
                    <label className='py-3'>Confirm Password</label>
                    <input type="password" className='bg-gray-100 rounded py-2 outline-none px-4' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className='flex justify-center mt-7'>
                    <button className='bg-[#FFCC40] border-0 py-2 px-8 focus:outline-none rounded text-lg hover:bg-[#FFCC40] text-black' onClick={resetPassword}>Reset Password</button>
                </div>
            </div>
        )
    }

    return (
        <div className='w-5/6 lg:w-1/3 mx-auto min-h-screen'>

            <div className='flex flex-col pt-32'>
                {
                  error ? (<p className=" text-error">{error}</p>) : null
                }
                <label className='py-2'>Enter email:</label>
                <input type="email" className='bg-gray-100 rounded py-2 outline-none px-4' value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='flex justify-center mt-7'>
                <button onClick={sendOtp} className="bg-[#FFCC40] border-0 py-2 px-8 focus:outline-none rounded text-lg hover:bg-[#FFCC40] text-black">Send OTP</button>
            </div>

        </div>
    )
}
