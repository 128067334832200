import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Search from '../Home/Search';
import Swal from 'sweetalert2';
import {baseURL} from '../../components/BaseURL';
import {UserContext} from "../../App";
import SearchV3 from "../Home/SearchV3";


const AdCateory = () => {

  // FOR CALENDAR


  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState('');

  const {country} = useParams();
  const [categories, setCategories] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [nearbyAreas, setNearbyAreas] = useState([]);
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1)
  const [sortBy, setSortBy] = useState('award_year')
  const [totalPage, setTotalPage] = useState(0)
  const [isNext, setIsNext] = useState(false)
  const [isPrev, setIsPrev] = useState(false)
  const [inputPageValue, setInputPageValue] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [noAds, setNoAds] = useState(false);

  const backToTop = () => {
    window.scrollTo({
      top: 0
    });
  }

  useEffect(() => {
    backToTop();
  }, []);

  useEffect(() => {
    fetch(`${baseURL}/get-all-categories`)
      .then((res) => res.json())
      .then((data) => {
          setCategories(data);
        }
      )
      .catch((err) => console.log(err));
  }, []);


  // useEffect(() => {
  //   fetch(`${baseURL}/get-ads-by-location/${country}/${selectedDate}`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setFilteredAds(data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [country, selectedDate]);

  useEffect(() => {
    getAdsV3(country, selectedDate, activePage, sortBy)
  }, [country, selectedDate]);
  const getAdsV3 = (c, d, pageNo, sort_by) => {
    setNoAds(false)
    if (!d) {
      d = date.toLocaleDateString();
      d = d.split('/')
      // newDate formate yyyy-mm-dd
      d = d[2] + '-' + d[0] + '-' + d[1];
    }
    fetch(`${baseURL}/v3/get-ads-by-location/${c}/${d}/${pageNo}/?sort_by=${sort_by}`)
      .then((res) => res.json())
      .then((data) => {
        setErrorMessage("")
        setFilteredAds(data['ads'])
        if (data['ads'].length <= 0) setNoAds(true)
        setActivePage(pageNo)
        setTotalPage(data['total_pages'])
        setIsNext(data['next'])
        setIsPrev(data['previous'])
      })
      .catch((err) => {
        console.log(err)
        setErrorMessage("Something went wrong. Please try again later.")
      })
  }



  useEffect(() => {
    fetch(`${baseURL}/get-nearby-areas/${country}`)
      .then((res) => res.json())
      .then((data) => {
        setNearbyAreas(data);
      })
      .catch((err) => console.log(err));

  }, [country]);


  useEffect(() => {
    // selected data dd-mm-yyyy
    let newDate = date.toLocaleDateString();
    newDate = newDate.split('/')
    // newDate formate yyyy-mm-dd
    newDate = newDate[2] + '-' + newDate[0] + '-' + newDate[1];
    setSelectedDate(newDate);

  }, [date]);

  // CLICKING ON PREMIUM DATA


  const proWarning = () => {
    Swal.fire({
      title: 'Premium data is only available for premium users',
      text: '',
      icon: 'warning',
      confirmButtonText: 'Upgrade to Premium',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        navigate('/upgrade');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: '',
          confirmButton: false,
          timer: 1000
        })
      }


    })

  }

  const handleNumberButtonClick = (event) => {
    if (activePage === parseInt(event.target.textContent, 10)) return null;
    // setResults([])
    getAdsV3(country, selectedDate, parseInt(event.target.textContent, 10), sortBy)
  }

  const handleNextButtonClick = async () => {
    // setResults([])
    getAdsV3(country, selectedDate, activePage + 1, sortBy)
  }

  const handlePrevButtonClick = async () => {
    if (activePage > 1 )
    {
      // setResults([])
      getAdsV3(country, selectedDate, activePage-1, sortBy)
    }
    return null
  }

  const handlePageNoSearch = async () => {
    // setResults([])
    setInputPageValue("")
    getAdsV3(country, selectedDate, inputPageValue, sortBy)
  }

  const handleChangePageInput = event => {
    if (event.target.value.length === 0)
    {
      setInputPageValue("")
      return
    }
    const value = Math.max(1, Math.min(totalPage, Number(event.target.value)));
    setInputPageValue(value);
  };

  function pagination() {
    if (totalPage <=5)
    {
      return (
        <>
          {
            isPrev ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            ) : null
          }
          {
            Array.from({ length: totalPage }, (_, index) => (
              <button
                key={index + 1}
                className={`paginationButton join-item btn btn-sm ${index + 1 === activePage ? 'paginationButtonActive' : ''}`}
                onClick={handleNumberButtonClick}
              >
                {index + 1}
              </button>
            ))
          }
          {
            isNext ? (
              <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
            ) : null
          }
        </>
      )
    } else {
      if (activePage < 5)
      {
        return (
          <>
            {
              isPrev ? (
                <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
              ) : null
            }
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${
                    index + 1 === activePage ? 'paginationButtonActive' : ''
                  }`}
                  onClick={handleNumberButtonClick}
                >
                  {index + 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 <= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${activePage + index - 1  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {activePage + index - 1}
                </button>
              ))
            }
            <>
              ....
            </>
            <button className="paginationButton join-item btn btn-sm ml-2" onClick={handleNumberButtonClick}>
              {totalPage}
            </button>
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      } else if (activePage+4 >= totalPage) {
        return (
          <>
            <button className="paginationButton join-item btn btn-sm" onClick={handlePrevButtonClick}> Prev </button>
            <>
              <button className="paginationButton join-item btn btn-sm mr-2" onClick={handleNumberButtonClick}>1</button>
            </>
            <>
              ....
            </>
            {
              Array.from({ length: 5 }, (_, index) => (
                <button
                  key={index + 1}
                  className={`paginationButton join-item btn btn-sm ${totalPage-5+1+index  === activePage ? 'paginationButtonActive' : ''} ${index === 0 ? 'ml-2' : ''}`}
                  onClick={handleNumberButtonClick}
                >
                  {totalPage-5+1+index}
                </button>
              ))
            }
            <input
              type="number"
              className="paginationInput join-item btn btn-sm w-[45px] [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield] bg-white border-2 hover:bg-white font-bold mr-2 text-gray-500"
              value={inputPageValue}
              onChange={handleChangePageInput}
              pattern="[0-9]*"
            />
            <>
              {
                isNext && !inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handleNextButtonClick}> Next</button>
                ) : !isNext && inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : inputPageValue ? (
                  <button className="paginationButton join-item btn btn-sm" onClick={handlePageNoSearch}>Submit</button>
                ) : null
              }
            </>
          </>
        )
      }
    }
  }
  const handleSortByChange = (event) => {
    if (event.target.value !== sortBy){
      setSortBy(event.target.value);
      getAdsV3(country, selectedDate, 1, event.target.value)
    }
  };


  return (
    <div className='min-h-screen w-11/12 lg:w-11/12 xl:w-5/6  mx-auto mb-20'>

      <SearchV3 country={country}/>

      <div className='flex flex-col lg:flex-row'>

        {/* ----- FIRST COLUMN ----- */}
        <div className='w-5/6 lg:w-[25%] mx-auto lg:mx-1 bg-gray-100 mb-7 lg:mb-0'>
          <div className='pt-5 pl-5'>

            <Link to="/my-account">
              <h3 className='text-gray-500 hover:text-gray-800  py-1 font-semibold cursor-pointer'>My Account</h3>
            </Link>
            <Link to={`/post/${country}`}>
              <h3 className='text-gray-500 hover:text-gray-800  py-1 font-semibold cursor-pointer'>Create a Posting</h3>
            </Link>
          </div>
          {/* ---------------CALENDAR--------------- */}
          <div className='p-1 '>
            <Calendar
              onChange={setDate}
              value={date}

            />
          </div>
          <div className='pt-5 pl-5 pb-10'>

            <Link to="/terms-of-use">
              <h3 className='text-gray-500 hover:text-gray-800  py-1 font-semibold cursor-pointer'>Terms of use</h3>
            </Link>

            <Link to="/privacy-policy">
              <h3 className='text-gray-500 hover:text-gray-800  py-1 font-semibold cursor-pointer'>Privacy Policy</h3>
            </Link>

            <Link to="/blog">
              <h3 className='text-gray-500 hover:text-gray-800  py-1 font-semibold cursor-pointer'>Blog</h3>
            </Link>
          </div>
        </div>

        {/* ----- SECOND COLUMN ----- */}
        <div className='w-5/6 lg:w-[50%] mx-auto'>
          <div className='bg-zinc-100 mb-2'>
            <h3 className='py-2 px-5 text-2xl'>Browse data for <span
              className='font-semibold text-gray-500 hover:text-gray-800 '> {country} </span>
            </h3>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {
              categories.map(category => {
                  if (!category.parent) {
                    return (
                      <div className='pb-4'>
                        <h4 className=' font-semibold mb-2  mr-2 py-2 pl-5 bg-gray-100 rounded'> {category.name}</h4>
                        {
                          categories.map(subcategory => {
                            if (subcategory.parent === category.id) {
                              if (category.name === 'Premium Data') {
                                return (
                                  <>
                                    <div className='text-gray-500 hover:text-gray-800 pl-5 flex justify-between pr-4'>
                                      <div onClick={proWarning}>{subcategory.name}</div>
                                      <div className='pl-2'>&#128274;</div>
                                    </div>
                                    <hr/>
                                  </>
                                )
                              } else {
                                return (
                                  <>
                                    <Link to={`/all-ads/${country}/${subcategory.name}/${selectedDate}`}
                                          className='text-gray-500 hover:text-gray-800 pl-5'>{subcategory.name}</Link>
                                    <hr/>
                                  </>
                                )
                              }
                            } else {
                              return null
                            }
                          })
                        }
                      </div>
                    )
                  } else {
                    return null
                  }
                }
              )
            }

          </div>
          {categories.length === 0 ? <div className='text-center'>Loading...</div> : null}
          <div className='pt-10 mb-20'>
            {
              filteredAds.length > 0 ? (
                <div className="mb-2">
                  <div className="flex flex-row justify-end items-center">
                    <div className="form-control mr-2">
                      <label className="label cursor-pointer">
                        <span className="label-text font-bold mr-2">Sort By </span>
                        <select
                          value={sortBy}
                          onChange={handleSortByChange}
                          className="select select-warning hover:outline-0 focus:outline-0"
                        >
                          <option value="award_year">Award Year</option>
                          <option value="alphabet">Alphabet</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              filteredAds.map((ad, index) => {
                let dateString = ''
                if (ad.description.includes('Award Year : ')) {
                  dateString = ad.description.split('Award Year : ')[1].split('\n')[0]
                }
                if (ad.is_premium) {
                  return (
                    <div key={index} onClick={() => {
                      if (userData.userData.subscription_type === 'paid') {
                        navigate(`/data/${ad.id}`)
                      } else {
                        Swal.fire({
                          title: 'You need to subscribe to view premium data',
                          text: "Do you want to subscribe now?",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, subscribe now!'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate('/upgrade')
                          }
                        })
                      }
                    }}>
                      <div className='flex justify-between w-full bg-white items-center pr-4 hover:bg-gray-200'>
                        <h3
                          className=' cursor-pointer text-gray-900 hover:text-gray-600 p-3  rounded mb-2 lg:pl-7 py-3 '>
                          <span className='text-gray-500 mr-5'> &#128970;  {dateString}</span> {ad.title}
                        </h3>
                        <div>&#128274;</div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={index}>
                      <Link to={`/data/${ad.id}`}>
                        <div
                          className='flex justify-between items-center bg-gray-100 pr-4 hover:bg-gray-300 transition duration-100  rounded mb-2 lg:pl-7 py-3  cursor-pointer text-gray-500 hover:text-gray-800 p-3  '>
                          <h3
                            className=''>
                            <span className='text-gray-500 mr-5'> &#128970;  {dateString}</span> {ad.title}
                          </h3>
                        </div>
                      </Link>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>


        {/* ----- THIRD COLUMN ----- */}
        <div className='w-5/6 lg:w-[25%] mx-auto lg:mx-1 bg-gray-100 p-2'>
          <select name="" id="" className='w-full'>
            <option value="">English</option>
          </select>
          <div className='pl-3 pt-4 text-gray-500 hover:text-gray-800  font-semibold'>
            <h3>Nearby areas</h3>
            {nearbyAreas.length === 0 ? <div className='text-center'>Loading...</div> : (
              nearbyAreas.map(area => {
                return (
                  <>
                    <Link to={`/all-ads/${area.name}`} className='text-gray-500 hover:text-gray-800 pl-5'>
                      {area.name}
                    </Link>
                    <hr/>
                  </>
                )
              })
            )}
          </div>
        </div>
      </div>

      <div className="text-center">
        {noAds ? <div>No data found</div> : null}
        {errorMessage ? <div>{errorMessage}</div> : null}

        <div className="join">
          {
            totalPage > 1 ? pagination() : null
          }
        </div>
      </div>

    </div>
  );
};

export default AdCateory;