import React, { useState } from "react";
import { Link } from "react-router-dom";
import City from "./City";

export default function Country({ allCountries, country }) {

    let areaFound = 0;

    // check if mobile device
    const isMobile = window.innerWidth < 768;

    const [hideCity, setHideCity] = useState(
        true
    );



    const toogleHideCity = () => {
        setHideCity(!hideCity);
    };

    return (
        <div
            key={country.id}
            className=" rounded-md p-2 "

        >
            <div>
                <div className="flex justify-between cursor-pointer " onClick={toogleHideCity}>
                    <div>
                        <div>
                            <h3 className="text-lg font-semibold pl-2">
                                {country.name}
                            </h3>
                        </div>
                    </div>
                    <div className="px-2">
                        {
                            hideCity ? (
                                <i className="bi bi-plus-square text-2xl text-gray-500"></i>
                            ) : (
                                <i className="bi bi-dash-square text-2xl text-gray-500"></i>
                            )
                        }
                    </div>
                </div>

                {hideCity ? null : (

                    <div>
                        {/* -------AREA LOOP HERE------ */}
                        {allCountries &&
                            allCountries.map((area, index) => {
                                if (
                                    area.type === "state" &&
                                    area.parent === country.id
                                ) {
                                    areaFound += 1;
                                    return (

                                        <City key={area.id} area={area} allCountries={allCountries} />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        {areaFound === 0 && (
                            <div key={country.id} className="mb-5">
                                <Link to={`/all-ads/${country.name}`}>
                                    <h4 className="text-gray-800 hover:text-sky-800  cursor-pointer pl-5 pt-3">
                                        {country.name}
                                    </h4>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
                <hr className="w-full" />
            </div>
        </div>
    );
}
