import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseURL } from '../../components/BaseURL';

const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordConfirm, setPasswordConfirm] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (password !== passwordConfirm) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        fetch(`${baseURL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                password,
            })
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.token) {
                    localStorage.setItem('classified-ads-token', data.token);
                    navigate('/verify-email');
                    Swal.fire({
                        title: 'Success!',
                        text: 'You have successfully registered!',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else if (data.error) {
                    setError(data.error);
                } else {
                    setError('Something went wrong! Please try again.');
                }
            })
    }

    return (
        <div>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
                    <div className="w-11/12 lg:w-2/5 md:w-1/2 md:pr-16 mx-auto lg:pr-0 pr-0">
                        <h1 className="title-font font-medium text-3xl text-gray-900">
                            Welcome to Projects Module
                        </h1>
                        <p className="leading-relaxed mt-4">
                            Register to our system and start posting your data.
                        </p>
                    </div>
                    <div className="w-5/6 lg:w-2/5 md:w-1/2 mx-auto bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto  mt-10 md:mt-0">
                        <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Sign Up</h2>
                        <div className='text-red-500'>
                            {error && <li>{error}</li>}
                        </div>
                        <div className="relative mb-4">
                            <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
                            <input onChange={(e) => setName(e.target.value)} type="text" id="name" name="name" placeholder='Type your full name' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" placeholder='Type your email' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label for="password" className="leading-7 text-sm text-gray-600">Password</label>
                            <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" name="password" placeholder='Type password' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label for="password2" className="leading-7 text-sm text-gray-600">Retype password</label>
                            <input onChange={(e) => setPasswordConfirm(e.target.value)} type="password" id="password2" name="password2" placeholder='Retype password' className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        {loading ? (
                            <button className="btn loading">Loading...</button>
                        ) : (
                            <button onClick={handleSubmit} className="bg-[#FFCC40] border-0 py-2 px-8 focus:outline-none rounded text-lg hover:bg-[#FFCC40] text-black">Register</button>
                        )}

                        <p className="text-xs mt-3">Already have an account? <Link to="/login" className="underline text-gray-500" >Login</Link> here.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Register;