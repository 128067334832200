import React from 'react';
import { Line } from 'react-chartjs-2';
import "chart.js/auto";

const data2 = {
    labels: ["SBIR Phase I","SBIR Phase II"],
    datasets: [
        {
            type: "bar",
            label: "Award Count",
            data: [3,1]
        }, {
            type: "line",
            label: "Award Amount",
            data: [165000,156767]
        }
    ]
}

const data3 = {
    labels: ["SBIR Phase I","SBIR Phase II"],
    datasets: [
        {
            type: "bar",
            label: "Award Count",
            data: [3,1]
        }, {
          type: 'line',
          label: 'Line Dataset',
          data: [50, 50],
      }
  
    ]
  };

const LineChart = ({data, adId}) => {

    const graphClickEvent = (event, elements) => {
        if (elements.length > 0) {
            const element = elements[0];
            if (element.datasetIndex === 0 || element.datasetIndex === 1) {
                if (!window.location.href.includes("chart-details")) window.open(`/chart-details/${adId}`, "_blank");
            }
        }
    }

    // logarithmic scale
    const options = {
        scales: {
            y: {
                type: 'logarithmic',
                ticks: {
                    callback: function(value, index, values) {
                        return value.toLocaleString();
                    }
                }
            }
        },
        onClick: graphClickEvent,
    }

    
    

  
  return (
    <Line data={data} options={options} />
  );
}

export default LineChart;
