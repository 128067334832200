

import React from "react";

export default function ChooseArea({
    allCountries,
    selectedCountry,
    selectedArea,
    setSelectedArea,
}) {
    let hasArea = false;
    return (
        <div className="pb-3">
            <h4 className="font-semibold pb-2">Choose state</h4>
            <select onChange={(e)=>setSelectedArea(e.target.value)} value={selectedArea} className="select select-accent w-full  border border-gray-600">
                <option value="">Select one</option>
                {allCountries &&
                    allCountries.map((area, index) => {
                        if (
                            area.type === "state" &&
                            area.parent === parseInt(selectedCountry)
                        ) {
                            hasArea = true;
                            return (
                                <option
                                    key={index}
                                    value={area.id}
                                >
                                    {area.name}
                                </option>
                            );
                        }
                        return null;
                    })}
                    {!hasArea && selectedCountry && allCountries && allCountries.map((parentCountry, index) => {
                        if (parentCountry.id === parseInt(selectedCountry)) {
                            return(
                                <option
                                    key={index}
                                    value={parentCountry.id}
                                    onChange={(e) =>
                                        setSelectedArea(e.target.value)
                                    }
                                >
                                    {parentCountry.name}
                                </option>
                            )
                        }
                    })}
            </select>
        </div>
    );
}
/*
import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ColourOption, colourOptions } from '../data';
import { ActionMeta, OnChangeValue } from 'react-select';

export default class CreatableSingle extends Component {

  handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  render() {
    return (
      <CreatableSelect
        isClearable
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={colourOptions}
      />
    );
  }
}

*/