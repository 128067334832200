/* eslint-disable react-hooks/exhaustive-deps */

//import React from 'react'
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export default function ChangeView({ center, zoom }) {
    const map = useMap();
    useEffect(() => {
        map.setZoom(zoom);
    }, [zoom]);

    useEffect(() => {
        map.setView(center, zoom);
    }, [center]);

    return null;
}
