import React, {useState} from 'react'
import { baseURL } from '../../components/BaseURL';

export default function VerifyEmail() {
    const [loading, setLoading] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [timer, setTimer] = React.useState(120);
    const [buttonEnabled, setButtonEnabled] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [error, setError] = useState("")

    React.useEffect(() => {
        if (timer > 0) {
            setTimeout(() => setTimer(timer - 1), 1000);
        } else {
            setButtonEnabled(true);
        }
    }, [timer]);


    React.useEffect(() => {
        const token = localStorage.getItem('classified-ads-token');
        if (token) {
            fetch(`${baseURL}/auth/get-email`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            })
                .then(res => res.json())
                .then(data => {
                    setLoading(false);
                    if (data.email) {
                        setEmail(data.email);
                    } else if (data.verified) {
                        window.location.href = '/';
                    }
                })
        } else {
            window.location.href = '/login';
        }
    }, []);


    const verifyEmail = () => {
        setLoading(true);
        fetch(`${baseURL}/auth/verify-otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('classified-ads-token')}`
            },
            body: JSON.stringify({
                otp: otp
            })
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.verified) {
                    window.location.href = '/';
                } else if (data.error) {
                    // alert(data.error);
                    setError(data.error)
                } else {
                    // alert('Something went wrong! Please try again.');
                    setError(data.error)

                }
            })
    }

    const resendOtp = () => {
        setLoading(true);
        setError("")
        fetch(`${baseURL}/auth/resend-otp`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('classified-ads-token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                if (data.response === 'sent') {
                    setTimer(120);
                    setButtonEnabled(false);
                    alert('OTP sent successfully!');
                } else {
                    alert('Something went wrong! Please try again.');
                }
            })
    }



    if (loading) {
        return (
            <div className="container min-h-screen">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className='py-32 text-xl text-center font-bold'>Loading...</h1>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='w-5/6 lg:w-1/3 mx-auto min-h-screen pt-32'>
            <h4>Email sent to: {email}</h4>
            <div className='flex flex-col'>
                {
                  error ? (<p className=" text-error">{error}</p>) : null
                }
                <label className='py-3'>Enter OTP:</label>
                <input className='bg-gray-100 rounded py-2 outline-none px-4' onChange={e => setOtp(e.target.value)} type="text" />
            </div>
            <div className='flex justify-center mt-7'>
                <button className='bg-[#FFCC40] px-7 py-2 text-black rounded' onClick={verifyEmail} type='button'>Verify</button>
            </div>
            <br />
            <div className='text-center'>
                {buttonEnabled ? <button onClick={resendOtp} className="text-center cursor-pointer text-gray-500  text-sm" >Resend OTP</button> : <button className="text-center cursor-pointer text-gray-500  text-sm" disabled>Resend OTP ({timer} seconds)</button>}
            </div>

        </div>
    )
}
