import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const generatePieChart = (jobData, COLORS, onClick, jobName) => {

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {jobData[index].value}
      </text>
    );
  }
  // Check if all values are 0
  const allValuesAreZero = jobData.every(entry => entry.value === 0);

  // If all values are 0, return "No data found"
  if (allValuesAreZero) {
    return (
      <div style={{ textAlign: 'center', marginTop: '150px', height: 150, maxWidth: 200 }}>
        <p style={{ marginBottom: '0' }} >No pie chart for this job</p>
      </div>
    );
  }

  return (
    <ResponsiveContainer height={300} width={300}>
      <PieChart>
        <Pie
          data={jobData}
          cx={120}
          cy={200}
          innerRadius={30}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={renderCustomizedLabel}
          labelLine={false}
          isAnimationActive={true}
        >
          {jobData.map((entry, entryIndex) => (
            <Cell style={{outline: 'none'}} key={entryIndex} fill={COLORS[entryIndex % COLORS.length]} onClick={(e) => onClick(jobName, e)} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const MyNestedPieChart = ({ continent }) => {
  const the_data = [];

  for (const job in continent) {
    if (continent.hasOwnProperty(job)) {
      const countries = continent[job];
      const country_data = [];

      for (const country in countries) {
        if (countries.hasOwnProperty(country)) {
          country_data.push({ name: country, value: countries[country] });
        }
      }

      the_data.push({ [job]: country_data });
    }
  }

  const COLORS = [
    "#FF6384",
    "#FF9F40",
    "#FFCD56",
    "#4BC0C0",
    "#36A2EB",
    "#3080D0",
  ];
  const portionOnClick = async (jobName, entryData) => {
    debugger
    let name = ""
    if (entryData.target.getAttribute("name")  === "Asia/Pacific/Middle East") {
      name = "Asia"
    } else {
      name = entryData.target.getAttribute("name")
    }
    window.open(`/ad-continent/${jobName}/${name}`, '_blank');
  }


  // Array to store the generated PieCharts
  const pieChartArray = [];

  the_data.forEach((job, index) => {
    const jobName = Object.keys(job)[0];
    const generatedPieChart = generatePieChart(job[jobName], COLORS, portionOnClick, jobName);
    pieChartArray.push({
      key: jobName,
      pieChart: generatedPieChart,
      jobName: jobName,
    });
  });

  return (
    <ul style={{
      display: 'flex',
      margin:"auto",
      marginTop: "-100px",
      flexWrap: "nowrap",
      overflowX: "auto",
      width: "100%",
      justifyItems: "center",
    }}>
      {pieChartArray.map((chart, index) => (
        <li key={chart.key} style={{ marginBottom: '10px' }}>
          {chart.pieChart}
          <h3 style={{ marginRight: "75px", textAlign: "center" }}>{chart.jobName}</h3>
        </li>
      ))}
    </ul>
  );
};

export default MyNestedPieChart;
