import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function City({ allCountries, area }) {
    const [hideArea, setHideArea] = useState(true);

    const toogleHideArea = () => {
        setHideArea(!hideArea);
    }

    let areaFound = 0;

    return (
        <div className="">
            {/* <Link to={`/all-ads/${area.name}`}> */}
            <div className="flex justify-between items-center pt-3 cursor-pointer" onClick={toogleHideArea}>
                <div>
                    <h4 className="text-sky-500 hover:text-sky-800  cursor-pointer pl-5">
                        {area.name}
                    </h4>
                </div>
                <div className="px-3">
                    {
                        hideArea ? (
                            <i className="bi bi-plus-square text-xl text-gray-500"></i>
                        ) : (
                            <i className="bi bi-dash-square text-xl text-gray-500"></i>
                        )
                    }
                </div>
            </div>
            {/* </Link> */}
            {hideArea ? null : (
                <div className="pl-3">
                    {/* -------CITY LOOP HERE------ */}
                    {allCountries &&
                        allCountries.map((city, index) => {
                            if (city.type === "city" && city.parent === area.id) {
                                areaFound += 1;
                                return (
                                    <Link to={`/all-ads/${city.name}`}>
                                        <div className="text-sky-500 hover:text-sky-800  cursor-pointer pl-5">
                                            {city.name}
                                        </div>
                                    </Link>
                                );
                            }
                            return null;
                        })}
                    {areaFound === 0 && (
                        <div className="text-sky-500 hover:text-sky-800  cursor-pointer pl-5">
                            <Link to={`/all-ads/${area.name}`}>
                                <h4 className="text-sky-500 hover:text-sky-800  cursor-pointer pt-3">
                                    {area.name}
                                </h4>
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
