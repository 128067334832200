import React from "react";
import Swal from "sweetalert2";
import { baseURL } from "../../components/BaseURL";
//import { getUserLocation } from "../Home/Home";

export default function SelectArea({ areas, selectedCountry, setAreas, setSelectedArea2, setValue, selectedState }) {
    let hasArea = false;
    const [showAreas, setShowAreas] = React.useState(false);
    const [selectedArea, setSelectedArea] = React.useState(null);
    const [areaName, setAreaName] = React.useState("");
    const [showInputArea, setShowInputArea] = React.useState(false);
    const [userLocation, setUserLocation] = React.useState(null);

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                //console.log(position);
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                //console.log(lat, lng);
                const userLocation = { lat, lng };
                setUserLocation(userLocation);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    React.useEffect(() => {
        if (selectedState) {
            const area = areas.find((area) => area.id === parseInt(selectedState));
            if (area) {
                setAreaName(area.name);
                setSelectedArea(area.id);
                setSelectedArea2(area.id);
            }
        }
    }, [selectedState]);

    React.useEffect(() => {
        getUserLocation();
    }, []);

    React.useEffect(() => {
        if (areaName.length > 0) {
            setShowInputArea(true);
        } else {
            setShowInputArea(false);
        }
    }, [areaName]);

    const handleAreaName = (name, id) => {
        setAreaName(name);
        setSelectedArea(id);
        //setValue("area", id);
        setSelectedArea2(id);
        setShowAreas(false);
    };

    const addAreaName = () => {
        //check if area name is already exists
        let areaNameExists = false;
        areas.map((area, index) => {
            if (area.name.toLowerCase() === areaName.toLowerCase()) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Area name already exists",
                });

                setAreaName(area.name);
                setSelectedArea(area.id);
                setSelectedArea2(area.id);
                areaNameExists = true;
            }
            return null;
        });
        if (true) { //
            if (!areaNameExists) {
                const data = {
                    name: areaName,
                    //latitude: userLocation.lat,
                    //longitude: userLocation.lng,
                    parent: selectedCountry,
                    zoom_level: 0,
                    type: "state",
                };
                if (userLocation) {
                    data.latitude = userLocation.lat;
                    data.longitude = userLocation.lng;
                }
                const url = `${baseURL}/add-area`;
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                };
                fetch(url, options)
                    .then((res) => res.json())
                    .then((data) => {
                        //setShowInputArea(false);
                        //setAreaName("");
                        //setShowAreas(false);
                        if (data.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Area added successfully",
                            });
                            setShowInputArea(false);
                            setShowAreas(false);
                            // add area to areas
                            setAreas([...areas, data.area]);
                            //setValue('area', data.area.id);
                            setSelectedArea2(data.area.id);

                        } else if (data.message === "Area already exists") {
                            alert("Area already exists");
                        } else {
                            alert("Something went wrong! Please try again.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        // else {
        //     alert("Please allow location permission");
        //     getUserLocation();
        // }
    };

    const handleSelectArea = () => {
        setShowAreas(!showAreas);
    }

    return (
        <div className="">
            <div onClick={handleSelectArea} className="select select-accent w-full border border-gray-500">
                <input
                    className="placeholder:text-slate-800 outline-0 w-full border-none"
                    type="text"
                    placeholder="Select one"
                    // onFocus={() => {
                    //     if (!showAreas) {
                    //         setShowAreas(true);
                    //     }
                    // }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        if (showAreas) {
                            setTimeout(() => {
                                setShowAreas(false);
                            }, 300);
                        }

                    }}
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                />
            </div>
            {showAreas && (
                <div className="relative">
                    <div
                        className="drop-shadow-lg bg-white border border-gray-500 absolute"
                        style={{ top: "100%", width: "100%" }}
                    >
                        {showInputArea && (
                            <div
                                onClick={addAreaName}
                                className="hover:bg-blue-500 hover:text-white px-3 text-base"
                            >
                                + {areaName}
                            </div>
                        )}

                        {areas.map((area) => {
                            if (area.parent === parseInt(selectedCountry)) {
                                hasArea = true;
                                return (
                                    <div
                                        onClick={() => {
                                            handleAreaName(area.name, area.id);
                                        }}
                                        className="hover:bg-blue-500 hover:text-white px-3 text-base"
                                        key={area.id}
                                        value={area.id}
                                    >
                                        {area.name}
                                    </div>
                                );
                            }
                            return null;
                        })}
                        {!hasArea &&
                            areas.map((area) => {
                                if (area.id === parseInt(selectedCountry)) {
                                    return (
                                        <>
                                            <div
                                                onClick={() => {
                                                    handleAreaName(
                                                        area.name,
                                                        area.id
                                                    );
                                                }}
                                                className="hover:bg-blue-500 hover:text-white px-3 text-base"
                                                key={area.id}
                                                value={area.id}
                                            >
                                                {area.name}
                                            </div>
                                        </>
                                    );
                                }
                                return null;
                            })}
                    </div>
                </div>
            )}
        </div>
    );
}
