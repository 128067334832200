import React, { useEffect, useState } from "react";
import "../../App.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import {
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import plusicon from "../../img/plusIcon.png";
import yellowPlusIcon from "../../img/Plus-Icon-Yellow-Circle.png"
import ChangeView from "./ChangeView";
import Continent from "./Continent";
import Search from "./Search";
import { useRef } from "react";
import { baseURL } from "../../components/BaseURL";
import SearchV3 from './SearchV3'



function MyComponent({ handleZoomLevel }) {
    const [zoomLevel, setZoomLevel] = useState(2); // initial zoom level provided for MapContainer

    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });

    handleZoomLevel(zoomLevel);

    return null;
}
/*
export const getUserLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            //console.log(position);
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            //console.log(lat, lng);
            const userLocation = { lat, lng };
            //console.log(userLocation);
            return userLocation;
        });
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
};
*/

const Home = () => {
    const [allCountries, setAllCountries] = useState([]);
    const [allContinents, setAllContinents] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(2); // initial zoom level provided for MapContainer
    const [center, setCenter] = useState([27, 30]);
    const [mouseOverPopupContainer, setMouseOverPopupContainer] = useState(false);
    const getLocationOnce = useRef(false);
    const [adsRegion, setAdsRegion] = useState([])

    // ask location permission from user

    useEffect(() => {
        if (!getLocationOnce.current) {
            getLocationOnce.current = true;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    //console.log(position);
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    //console.log(lat, lng);
                    //const userLocation = { lat, lng };
                    //console.log(userLocation);
                    setCenter([lat, lng]);
                    setZoomLevel(5);

                }, (error) => {
                    console.log(error);
                    alert("Turn on your device location services to get better experience");
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        }
    }, []);

    /*React.useEffect(() => {
        // set user ip
        fetch('https://geolocation-db.com/json/')
            .then(res => res.json())
            .then(data => {
                setCenter([data.latitude, data.longitude]);
                setZoomLevel(5);
            })
    }, []);*/

    const markerIcon = L.icon({
        iconUrl: yellowPlusIcon,
        iconSize: [20, 20],
    });

    useEffect(() => {
        fetch(`${baseURL}/get-all-areas`)
            .then((res) => res.json())
            .then((data) => {
                setAllCountries(data);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        fetch(`${baseURL}/get-all-continents`)
            .then((res) => res.json())
            .then((data) => {

                setAllContinents(data);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleZoomLevel = (zoom) => {
        setZoomLevel(zoom);
    };

    const changeCenter = (lat, long) => {
        setZoomLevel(zoomLevel + 2);
        setCenter([lat, long]);
    };


    // HIDE and SEE states

    const [hideCountry, setHideCountry] = useState(true);
    const [hideArea, setHideArea] = useState(true);
    const [hideCity, setHideCity] = useState(true);

    const [countryId, setCountryId] = useState(null);
    const [areaId, setAreaId] = useState(null);
    const [cityId, setCityId] = useState(null);





    const toogleHideCountry = (id) => {
        setHideCountry(!hideCountry);
        setCountryId(id);
    }

    const toogleHideArea = () => {
        setHideArea(!hideArea);
    }

    const toogleHideCity = () => {
        setHideCity(!hideCity);
    }






    return (
        <div>
            {/*<h2 className="text-4xl font-semibold py-7 text-center">*/}
            {/*    Projects Module*/}
            {/*</h2>*/}


            <div className="mt-10">
                <ul className="flex flex-col md:flex-row justify-center">
                    <AnchorLink
                        href="#5"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        South America
                    </AnchorLink>
                    <AnchorLink
                        href="#4"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        North America
                    </AnchorLink>

                    <AnchorLink
                        href="#2"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        Europe
                    </AnchorLink>
                    <AnchorLink
                        href="#1"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        Asia/Pacific/Middle East
                    </AnchorLink>
                    <AnchorLink
                        href="#7"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        Australia
                    </AnchorLink>

                    <AnchorLink
                        href="#3"
                        className=" font-semibold text-center p-2 hover:border-b-2 border-[#FFCC40]"
                    >
                        Africa
                    </AnchorLink>
                </ul>
            </div>

            {/* ----------HOMEPAGE SEARCH----------- */}

            <SearchV3 />

            {/*{*/}
            {/*    adsRegion ? (*/}
            {/*        <div className="mt-5 flex items-center justify-center">*/}
            {/*            <BarChartComponent data={adsRegion}/>*/}
            {/*        </div>*/}
            {/*    ) : (*/}
            {/*      <></>*/}
            {/*    )*/}
            {/*}*/}

            <div className="mb-10 mt-5">
                <div className="mapouter relative text-right w-full mx-auto ">
                    <div className="gmap_canvas overflow-hidden bg-none ">
                        <MapContainer
                            center={center}
                            zoom={zoomLevel}
                            attributionControl={false}
                            minZoom={2}
                            maxBoundsViscosity={1.0}
                        // call handleZoomChange when the zoom level changes
                        >
                            <ChangeView center={center} zoom={zoomLevel} />
                            <MyComponent handleZoomLevel={handleZoomLevel} />
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />

                            {allCountries &&
                                allCountries.map((item, index) => {
                                    if (
                                        item.zoom_level <= zoomLevel &&
                                        item.zoom_level > 0 &&
                                        (item.type !== "continent" || item.name === 'United States') &&
                                        item.latitude !== null &&
                                        item.longitude !== null
                                    ) {
                                        return (
                                            <Marker
                                                key={index}
                                                position={[
                                                    item.latitude,
                                                    item.longitude,
                                                ]}
                                                eventHandlers={{
                                                    mouseover: (event) =>
                                                        event.target.openPopup(),
                                                    // close the popup when the mouse leaves the marker but don't close it when mouse over in the popup
                                                    mouseout: (event) => {
                                                        setTimeout(() => {
                                                            if (!mouseOverPopupContainer) {
                                                                event.target.closePopup();
                                                            }
                                                        }, 3000);
                                                    },

                                                    click: (event) =>
                                                        changeCenter(
                                                            item.latitude,
                                                            item.longitude
                                                        ),
                                                }}
                                                icon={markerIcon}
                                            >
                                                <Popup>
                                                    {item.parent ? (
                                                        <Link
                                                            // onMouseOver={() => {setMouseOverPopupContainer(true)}}
                                                            // onMouseOut={() => {setMouseOverPopupContainer(false)}}
                                                            to={`/all-ads/${item.name}`}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    ) : (
                                                        item.name
                                                    )}
                                                </Popup>
                                            </Marker>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                        </MapContainer>
                    </div>
                </div>
            </div>

            <div className="w-5/6 mx-auto mb-20">
                {/* -------CONTINENT LOOP HERE------ */}
                {allCountries &&
                    allCountries.map((continent, index) => {
                        if (continent.type === "continent") {
                            return (
                                <Continent key={index} allCountries={allCountries} continent={continent} />
                            );
                        } else {
                            return null;
                        }
                    })}
            </div>
        </div>
    );
};

export default Home;
