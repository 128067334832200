import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { Circle, MapContainer, Marker, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon } from "react-share";
import CharContainer from "./CharContainer";
import { baseURL } from "../../components/BaseURL";
import { UserContext } from '../../App';



const SingleAd = () => {
    const userData = useContext(UserContext);
    const { singlead } = useParams();
    const [ad, setAd] = useState({});
    const fetchAd = useRef(true);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (fetchAd.current) {
            fetch(`${baseURL}/get-single-ad/${singlead}`)
                .then((res) => res.json())
                .then((data) => {
                    setAd(data);
                })
                .catch((err) => console.log(err));
        }
        fetchAd.current = false;
    }, [singlead]);

    useEffect(() => {
        if (ad.id && userData.userData) {
            // if (data?.is_premium && userData.userData.subscription_type === 'free') window.location.href = '/upgrade';
            if (ad.is_premium && userData.userData.subscription_type === "free") {
                window.location.href = "/upgrade";
            }
        }
    }, [ad, userData]);

    // Send an email
    const sendEmail = () => {
        window.location.href = `mailto:${ad.email}`;
    };

    // Call a phone
    const callPhone = () => {
        window.location.href = `tel:${ad.phone}`;
    };

    // this page url for share
    const pageUrl = window.location.href;


    React.useEffect(() => {
        if (ad.id) {
            fetch(`${baseURL}/check-bookmark/${ad.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + localStorage.getItem("classified-ads-token"),
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setIsBookmarked(data.status);
                })
                .catch((err) => console.log(err));
        }
    }, [ad]);

    React.useEffect(() => {
        if (ad.id) {
            fetch(`${baseURL}/get-chart-info/${ad.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status === "success") {
                        setChartData(data);
                    } else {
                        console.log("No chart data");
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [ad]);


    useEffect(() => {
        const backToTop = () => {
            window.scrollTo({
                top: 0,
            });
        };
        backToTop();
    }, []);

    if (Object.keys(ad).length === 0) {
        return (
            <div className="min-h-screen text-center pt-20">
                <h3 className="text-2xl font-semibold">Loading...</h3>
            </div>
        );
    }

    const removeBookmark = () => {
        fetch(`${baseURL}/remove-bookmark/${ad.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + localStorage.getItem("classified-ads-token"),
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setIsBookmarked(false);
            })
            .catch((err) => console.log(err));
    }

    const addBookmark = () => {
        fetch(`${baseURL}/add-bookmark/${ad.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + localStorage.getItem("classified-ads-token"),
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setIsBookmarked(true);
            }).catch((err) => console.log(err));
    }


    const desiredFields = [
        'Title',
        'Company',
        'Awarding Agency',
        'Year',
        'Amount',
        'Phase',
        'State',
        'Awardee Contact',
        'Awardee email',
        'Abstract',
        'Agency',
        'Award Year',
        "Award Amount",
        "Contact Name",
        "PI Email"
    ];

    return (
        <div className="min-h-screen w-5/6 lg:w-3/4 mx-auto mb-20">
            <h5 className="pb-5 pt-10 text-3xl">
                {" "}
                {ad.area["name"]}, {ad.parentArea.name}{" "}
            </h5>
            <p className="pb-7">
                {ad.area["name"]}, {ad.sub_category["name"]}
            </p>

            <div className="flex flex-col lg:flex-row">
                {ad.image?(
                    <div className="w-full lg:w-[50%] mx-auto mb-5">
                        <img
                            className="w-full object-cover bg-gray-200"
                            src={
                                ad.image
                                    ? `${baseURL}${ad.image}`
                                    : "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"
                            }
                            alt=""
                        />
                    </div>
                    ):null}
                <div className="lg:px-10 w-full lg:w-[60%] ">
                    <h3 className="text-2xl font-semibold">{ad.title} </h3>
                    <div className="pt-3">
                        {
                            ad.description.split("\n").map((line, index) => {
                                if (line === "") {
                                    return <br key={index}/>;
                                }

                                const [key, value] = line.split(' : ');

                                if (key && desiredFields.includes(key.trim())) {
                                    return (
                                      <div key={index} style={{borderBottom: '1px solid lightgray'}}>
                                          <strong>{key.trim()}:</strong> {value.trim()}
                                      </div>
                                    );
                                }

                                return null;
                            })
                        }
                    </div>

                    {chartData ? (
                      <div>
                          <CharContainer adId={ad.id} data={chartData}/>
                      </div>
                    ) : null}

                    <div className="mt-10 flex flex-col lg:flex-row">
                        <button
                          onClick={sendEmail}
                          className="w-full lg:w-1/4 text-sm py-2 bg-gray-200 hover:bg-gray-500 rounded mx-1 mb-2 transition duration-150"
                        >
                            Reply
                        </button>
                        <button
                          onClick={callPhone}
                          className="w-full lg:w-1/4 text-sm py-2 bg-gray-200 hover:bg-gray-500 rounded mx-1 mb-2 transition duration-150"
                        >
                            Call
                        </button>
                        {isBookmarked ? (
                          <button onClick={removeBookmark}
                                  className="w-full lg:w-1/4 text-sm py-2 bg-gray-300 hover:bg-gray-500 rounded mx-1 mb-2 transition duration-150">
                              Bookmarked
                          </button>
                        ) : (
                          <button onClick={addBookmark}
                                  className="w-full lg:w-1/4 text-sm py-2 bg-gray-200 hover:bg-gray-500 rounded mx-1 mb-2 transition duration-150">
                              Bookmark
                          </button>
                        )}
                    </div>
                    <div>
                        <h3 className="pb-2">Share in social media:</h3>
                        <div className="flex">
                            <div className="m-1">
                                <FacebookShareButton
                                  url={pageUrl}
                                  quote={"Check this out"}
                                  hashtag={"#classifiedads"}
                                >
                                    <FacebookIcon size={32} round/>
                                </FacebookShareButton>
                            </div>
                            <br/>
                            <div className="m-1">
                                <WhatsappShareButton
                                  url={pageUrl}
                                  title={ad.title}
                                  separator=": "
                                >
                                    <WhatsappIcon size={32} round/>
                                </WhatsappShareButton>
                            </div>
                            <br/>
                            <div className="m-1">
                                <TwitterShareButton
                                  title={"test"}
                                  url={pageUrl}
                                >
                                    <TwitterIcon size={32} round/>
                                </TwitterShareButton>
                            </div>
                            <br/>
                            <div className="m-1">
                                <LinkedinShareButton
                                  url={pageUrl}
                                  title={ad.title}
                                  summary={ad.description}
                                  source={"Classified Ads"}
                                >
                                    <LinkedinIcon size={32} round/>
                                </LinkedinShareButton>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex flex-col lg:flex-row mt-10">
                {/* -----------------INTEGRATE MAP HERE------------------- */}

                <div className="w-full lg:w-[50%] mx-auto lg:mr-5 ">
                    <h4 className="text-lg font-semibold">Location:</h4>
                    {ad.latitude && ad.longitude ? (
                      <MapContainer
                        center={ad.latitude && ad.longitude ? [ad.latitude, ad.longitude] : [0, 0]}
                            zoom={17}
                            attributionControl={false}
                            minZoom={2}
                            maxBoundsViscosity={1.0}
                        // call handleZoomChange when the zoom level changes
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
{/* 
                            <Marker
                                position={[ad.latitude, ad.longitude]}
                            // eventHandlers={{
                            //     mouseover: (event) => event.target.openPopup(),
                            //     click: (event) =>
                            //         changeCenter(item.latitude, item.longitude),
                            // }}
                            // icon={markerIcon}
                            ></Marker> */}
                            <Circle
                                center={[ad.latitude, ad.longitude]}
                                pathOptions={{ color: "red" }}
                                radius={100}
                            ></Circle>
                        </MapContainer>
                    ) : (
                        <div>
                            <h3 className="text-2xl font-semibold p-14">
                                No location found
                            </h3>
                        </div>
                    )}
                </div>
                <div className="w-5/6 lg:w-[50%] mx-auto  p-14">

                </div>
            </div>
        </div>
    );
};

export default SingleAd;
