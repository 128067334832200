import React from 'react'

export default function ContactUs() {
  return (
    <div className='md:w-9/12 mx-auto mt-5 mb-16 px-2'>
        <h1 className='text-2xl font-bold mb-2'>Contact Us</h1>
        <div>
            Crosslinker is located in the heart of Silicon City, California, USA. 
            You may contact us through email: crosslinker.ai@gmail.com
        </div>
    </div>
  )
}
