import React from 'react';
import useWindowSize from '../hooks/useWindowSize';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const BarChartComponent = ({ inputData, clickFunction }) => {
  const output_data = Object.keys(inputData[Object.keys(inputData)]).map(region => ({
    "name": region,
    "Data": inputData[Object.keys(inputData)][region]
  }));
  let size = useWindowSize();
  const maxValue = Math.max(...output_data.map(entry => entry.Data)) + 20;
  const roundedMaxValue = Math.ceil(maxValue / 5) * 5;

  let containerWidth;

  if (size?.width <= 800) {
    containerWidth = '100%';
  } else if (size?.width > 800 && size?.width <= 1030) {
    containerWidth = '100%';
  } else {
    containerWidth = '100%';
  }

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <div style={{ width: containerWidth }}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={output_data} layout="vertical" margin={{ top: 20, right: 10, left: 100, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" domain={[0, roundedMaxValue]} />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="Data"
              fill="#192e5b"
              background={{ fill: 'transparent', cursor: "pointer" }}
              onClick={clickFunction}
              cursor="pointer"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
