import React from 'react'

export default function Jobs() {
  return (
    <div className='md:w-9/12 mx-auto mt-5 mb-16 px-2'>
        <h1 className='text-2xl font-bold mb-2'>Jobs</h1>
        <div>
            Working in Startup companies is not everyone’s game. A significant 
            amount of work and capabilities are required from the employee. If 
            you think that you are passionate to make a difference in the society, 
            come and join us. We are always looking for individuals who are aligned 
            to our mission. Feel free to send us a resume at: 
            <b> crosslinker.ai@gmail.com</b>.
        </div>
    </div>
  )
}
