import React from 'react';

const Blog = () => {

    // Go top of page
    window.scrollTo(0, 0);

    return (
        <div className='min-h-screen w-5/6 mx-auto mb-20'>


            <section>
                <h1 className='text-3xl font-bold py-10'>Blogs</h1>

                {/* Write some blog */}
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <h1 className='text-3xl font-bold py-10'>Write some blog</h1>
                        <p className='text-xl'>You can write some blog here</p>
                    </div>
                </div>

                {/* Blogs */}
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-col items-center justify-center'>
                        <h1 className='text-3xl font-bold py-10'>Blogs</h1>
                        <p className='text-xl'>You can write some blog here</p>
                    </div>
                </div>



            </section>

        </div>
    );
};

export default Blog;