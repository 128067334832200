import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {baseURL} from '../../components/BaseURL';
import EditProfile from '../../components/Modals/EditProfile';
import uploadIcon from "../../img/upload-image.png"

const MyAccount = () => {

  const navigate = useNavigate();

  // FOR EDIT MODAL
  let [isOpen, setIsOpen] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const [myBookmarks, setMyBookmarks] = useState([])
  const [paidSubscription, setPaidSubscription] = React.useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  // Go top of page
  window.scrollTo(0, 0);

  //   get uploaded image data and send it to the server
  const handleImageChange = (e) => {
    setProfilePicture(e.target.files[0])
    const url = baseURL + '/change-profile-picture'
    const formData = new FormData()
    formData.append('picture', e.target.files[0])
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
      },
      body: formData
    })
      .then(res => res.json())
      .then(data => {
        window.location.reload()
      })
      .catch(err => console.log(err))
  }

  // delete action
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        fetch(baseURL + '/delete-ad/' + id, {
          method: 'GET',
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(data => {
            if (data.status === 'success') {
              // remove the deleted ad from setUserData.ads
              let newAds = [...userData.ads];
              newAds = newAds.filter(ad => ad.id !== id);
              setUserData({...userData, ads: newAds});
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
              window.location.reload();
            } else {
              Swal.fire(
                'Error!',
                'Something went wrong.',
                'error'
              )
            }
          }).catch(err => {
          console.log(err)
        })

      }
    })
  }


  const [userData, setUserData] = React.useState({});

  React.useEffect(() => {
    fetch(baseURL + '/get-profile-data', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        setUserData(data);
        // console.log(data.subscription_type)
        if (data.subscription_type === 'paid') {
          setPaidSubscription(true)
        }
      }).catch(err => {
        console.log(err)
      }
    )
  }, [])

  React.useEffect(() => {
    fetch(baseURL + '/get-bookmarks', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.detail === 'Invalid token.')
        {
          navigate('/login')
        }
        setMyBookmarks(data);
      }).catch(err => {
        console.log(err)
      }
    )
  }, [])


  const deleteAd = (id) => {
    fetch(baseURL + '/delete-ad/' + id, {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        userData.ads.splice(userData.ads.findIndex(ad => ad.id === id), 1);
        setUserData(userData);
      }).catch(err => {
      console.log(err)
    })
  }


  const removeBookmark = (id) => {
    fetch(baseURL + '/remove-bookmark/' + id, {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 'success') {
          // remove the deleted ad from myBookmarks
          let newBookmarks = [...myBookmarks];
          newBookmarks = newBookmarks.filter(bookmark => bookmark.id !== id);
          setMyBookmarks(newBookmarks);
        }
      }).catch(err => {
        console.log(err)
      }
    )
  }

  React.useEffect(() => {
    // check token existance
    if (localStorage.getItem('classified-ads-token') === null) {
      navigate('/login')
    }
  }, [])

  const navigateVerifyEmail = () => {
    fetch(baseURL + '/auth/resend-otp', {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        navigate('/verify-email')
      }).catch(err => {
      console.log(err)
    })
  }

  const deleteImage = (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("deleteImage")
    fetch(baseURL + "/auth/delete-profile-image", {
      method: 'DELETE',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('classified-ads-token'),
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setUserData(data.data)
          window.location.reload();
        } else {
          console.log('something went wrong')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  return (
    <div className='min-h-screen w-5/6 mx-auto mb-20'>
      <div className=' flex flex-col lg:flex-row justify-between my-10'>
        <h1 className='text-3xl font-bold my-5 lg:my-0 text-center lg:text-left'>My account</h1>
        <button onClick={openModal} className='bg-[#FFCC40] px-6 py-2 rounded text-black'>Edit profile</button>
      </div>

      <div className='flex flex-col lg:flex-row'>
        <div className='w-5/6 lg:w-[20%] mx-auto flex flex-col items-center'>
          <label>
            <div className="relative">
              <img
                className='w-[120px] h-[120px] rounded-full' alt=''
                src={profilePicture ? URL.createObjectURL(profilePicture) : userData.image ? baseURL + userData.image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMpK_Cmq0XlLag7NmaAO_iqE80zoFyrK8yNzh7nT1Hswv3JZ7KjaLksLkc4lmWtouWxls&usqp=CAU"}
              />

              <div className="left-0 bottom-0 cursor-pointer bg-white">
                <input onChange={handleImageChange} className='cursor-pointer w-32 absolute inset-0 invisible bg-white'
                       type="file" accept="image/*" name="upload-image"/>
                <img id='upload-image' className='absolute w-[30px] rounded-full left-0 bottom-0 cursor-pointer bg-white border-2'
                     src={uploadIcon}
                     alt="change photo"/>
              </div>

              {/*{*/}
              {/*  userData.image ? (*/}
              {/*    <button onClick={deleteImage}>*/}
              {/*      <img className='absolute w-[30px] rounded-full right-0 bottom-0 cursor-pointer' src={deleteIcon}*/}
              {/*      />*/}
              {/*    </button>*/}
              {/*  ) : null*/}
              {/*}*/}
            </div>
          </label>
          <div className='mt-4'>
            {/*<div className='cursor-pointer font-semibold text-sm mt-3 px-3 py-2 text-gray-500 underline rounded'>*/}
            {/*  <h3 className='cursor-pointer text-[#FFCC40]'>Change profile image</h3>*/}
            {/*  <input onChange={handleImageChange} className='cursor-pointer w-32 opacity-0 relative bottom-4'*/}
            {/*         type="file"*/}
            {/*         accept="image/*"*/}
            {/*         name=""*/}
            {/*         id=""/>*/}
            {/*</div>*/}
            {userData.image ? (
              <button className="bg-red-200 px-4 py-1 rounded" onClick={deleteImage}>
                <p className="text-red-600">Delete Image</p>
              </button>
            ) : null}
          </div>
        </div>
        <div className='w-5/6 lg:w-[80%] mx-auto'>
          <h3 className='font-semibold py-3 px-5 bg-gray-50 rounded mt-1'>
            Name: <span className='text-gray-800 font-normal'>
                            {userData?.first_name}
                        </span>
          </h3>

          <div className='py-3 pr-5 bg-gray-50 rounded mt-1 flex flex-col lg:flex-row justify-between'>
            <h3 className='font-semibold py-3 px-5 bg-gray-50 rounded mt-1'>
              Email: <span className='text-gray-800 font-normal'>
                                {userData?.email}
                            </span>
              {userData?.email_verified ? null : <span className=' text-red-500 text-sm'> (Not Verified)</span>}
            </h3>
            {
              userData?.email_verified ?
                <button className='bg-emerald-500 px-6 py-2 rounded text-white'>Verified</button> :
                <button onClick={navigateVerifyEmail} className='bg-[#FFCC40] px-6 py-2 rounded text-black'>Verify
                  Email</button>
            }

          </div>
          <h3 className='font-semibold py-3 px-5 bg-gray-50 rounded mt-1'>
            Phone: <span className='text-gray-800 font-normal'>
                            {userData && userData.phone}
                        </span>
          </h3>
          <h3 className='font-semibold py-3 px-5 bg-gray-50 rounded mt-1'>
            Address: <span className='text-gray-800 font-normal'>
                            {userData && userData.address}
                        </span>
          </h3>
          <div className='py-3 px-5 bg-gray-50 rounded mt-1 flex flex-col lg:flex-row justify-between'>
            <h3 className='font-semibold'>
              Subscription: <span className='text-gray-800 font-normal'>
                                {
                                  paidSubscription ? (<>Pro</>) : (<>Free</>)
                                }
                            </span>
            </h3>
            {
              paidSubscription ? (
                <></>
              ) : (
                <>
                  <Link to="/upgrade">
                    <button
                      className='font-semibold text-sm animate-pulse hover:bg-[#FFCC40] transition duration-150  bg-[#FFCC40] py-2 px-4 rounded text-black my-2 lg:my-0 text-center'>
                      Upgrade to Pro
                    </button>
                  </Link>

                </>
              )
            }
          </div>

          <div>
            <h3 className='py-3 px-5 rounded mt-5'>
              My Datas:
            </h3>

            <div>
              {/* {
                        Array(10).fill(0).map((_, index) => (
                            <div key={index} className="flex flex-col lg:flex-row items-center" >
                                <div className='flex flex-col items-center justify-between px-5 py-3 bg-gray-50 rounded mt-1 w-5/6 lg:w-[85%] mx-auto' >
                                    <h3>Data</h3>
                                </div>
                                <div className='w-5/6 lg:w-[15%] mx-auto'>
                                    <button onClick={handleDelete} className='bg-red-400 px-5 py-[14px] relative top-0.5 text-white rounded lg:ml-2 text-sm my-2 lg:my-0'>Delete</button>
                                </div>
                            </div>

                        ))
                    } */}
              {userData.ads && userData.ads.map((data, index) => {

                let date = new Date(data.created_at);
                let dateString = date.toLocaleDateString();
                let newDate = dateString.split('/')
                // newDate formate yyyy-mm-dd
                newDate = newDate[2] + '-' + newDate[0] + '-' + newDate[1];
                return (
                  <div key={index} className="flex flex-col lg:flex-row items-center justify-between">
                    <div className='w-5/6 lg:w-[80%] mx-auto'>
                      <Link to={`/all-ads/${data.area.name}/${data.sub_category.name}/${newDate}/${data.id}`}>
                        <div
                          className='flex flex-col items-center justify-between px-5 py-3 bg-gray-50 rounded mt-1 mx-auto'>
                          <h3>{data.title}</h3>
                        </div>
                      </Link>
                    </div>
                    <div className='w-5/6 lg:w-[20%] mx-auto'>
                      <button onClick={() => handleDelete(data.id)}
                              className='bg-red-400 w-full px-5 py-[14px] relative top-0.5 text-white rounded lg:ml-2 text-sm my-2 lg:my-0'>Delete
                      </button>
                    </div>
                  </div>
                )
              })}
              {userData.ads && userData.ads.length === 0 && <div className='ml-6 text-gray-400'>No data found</div>}
            </div>
          </div>

          <div>
            <h3 className='py-3 px-5 rounded mt-5'>
              My Bookmarks:
            </h3>

            <div>
              {myBookmarks && myBookmarks.map((data, index) => (
                <div key={index} className="flex flex-col lg:flex-row items-center">
                  <div className='w-5/6 lg:w-[80%] mx-auto'>
                    <Link to={`/data/${data.ads.id}`}>
                      <div className='flex flex-col items-center justify-between px-5 py-3 bg-gray-50 rounded mt-1 '>
                        <h3>{data.ads.title}</h3>
                      </div>
                    </Link>
                  </div>
                  <div className='w-5/6 lg:w-[20%] mx-auto'>
                    <button onClick={() => removeBookmark(data.ads.id)}
                            className='bg-red-400 w-full px-5 py-[14px] relative top-0.5 text-white rounded lg:ml-2 text-sm my-2 lg:my-0'>Delete
                    </button>
                  </div>
                </div>
              ))}
              {myBookmarks && myBookmarks.length === 0 &&
                <div className='ml-6 text-gray-400'>No bookmarked data found</div>}
            </div>
          </div>
        </div>
      </div>


      {/* EDIT MODAL */}
      <EditProfile
        userData={userData}
        setUserData={setUserData}
        isOpen={isOpen}
        closeModal={closeModal}

      />

    </div>
  );
};

export default MyAccount;