import React from 'react'

export default function ChooseCategory({categories, selectedCategory, setSelectedCategory, setSelectedSubCategory}) {
  return (
    <div className='pb-3'>
        <h4 className='font-semibold pb-2'>Choose category</h4>
        <select onChange={(e)=> {setSelectedCategory(e.target.value); setSelectedSubCategory('')}} className="select select-accent w-full border border-gray-600">
            <option value={selectedCategory}>Select one</option>
            {categories && categories.map((category, index) => {
                if (category.parent === null) {
                    return <option key={index} value={category.id}>{category.name}</option>
                }
                return null;
            })}
        </select>
    </div>
  )
}
