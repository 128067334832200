import React, { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL } from "../BaseURL";
import logoImage from "../../img/logos.png";
import Profile from "../../img/profile.png";

const Navbar = () => {
  const [userData, setUserData] = useState({});
  const [paidSubscription, setPaidSubscription] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const currentUrl = location.pathname;
  console.log(currentUrl, "url");
  React.useEffect(() => {
    fetch(baseURL + "/get-profile-data", {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("classified-ads-token"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUserData(data);
        if (data.subscription_type === "paid") {
          setPaidSubscription(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [loggedIn, setLoggedIn] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem("classified-ads-token")) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("classified-ads-token");
    setLoggedIn(false);
    Swal.fire({
      title: "Logged out successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/";

    // have to do more
  };
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <div className="mainHeader">
          <div className="logo">
            <Link href="/login">
              <img
                alt="logo"
                src={logoImage}
                height={150}
                width={220}
                className="object-fill w-[170px] lg:w-[220px]  "
              />
              <div className="text-sm font-medium text-black">
                {/* A startup for the startups */}
                Empowering the Startup Ecosystem
              </div>
            </Link>
          </div>

          <ul className="navbar">
            {/* <li onClick={()=>navigate("/http://digimonk.net:1836/")}> */}
            <li>
              <Link to={"https://projects.crosslinker.us/"}>
                <span className="item"> Project</span>
              </Link>
            </li>
            {/* </li> */}
            <li>
              <Link to="https://testing.crosslinker.us/login">
                <span className="item">Testing</span>
              </Link>
            </li>
            <li>
              <Link to="https://service.crosslinker.us/login">
                <span className="item"> Service</span>
              </Link>
            </li>
          </ul>
          <ul
            className="Navbar" 
            style={{ position: "relative" }}
          >
            <li href="">{/* <span className="item">Sign Up</span> */}</li>
            <li>
            <Link to="#">
              <span className="item">Login</span>
              </Link>
            </li>
            <li onClick={handleOpen}>
              <img src={Profile} height={30} width={30} />
            </li>
            {currentUrl === "/login" ? (
              ""
            ) : (
              <>
                {" "}
                {open && (
                  <div
                    style={{
                      position: "absolute",
                      background: "#fff",
                      width: "131px",
                      left: "-50px",
                      padding: "7px 6px",
                      borderRadius: "8px",
                      zIndex: "999",
                      top: "55px",
                      boxShadow: "0px 0px 7px #ccc",
                    }}
                  >
                    <Link href={""}>
                      <li
                        style={{
                          padding: "3px 2px 9px",
                          borderBottom: "1px solid #ccc",
                          fontSize: "14px",
                        }}
                      >
                        Logout
                      </li>
                    </Link>
                    <Link href={""}>
                      <li
                        style={{
                          padding: "8px 2px",
                          borderBottom: "1px solid #ccc",
                          fontSize: "14px",
                        }}
                      >
                        My Profile
                      </li>
                    </Link>
                  </div>
                )}
              </>
            )}
          </ul>
        </div>

        {/* --------------FOR DESKTOP----------- */}

        {/* {!loggedIn && (
          <div className="navbar-center lg:flex navbar-end">
            <ul className="menu menu-horizontal p-0">
              <li>
                <Link to="/login" className="text-[#FFCC40]">
                  Login
                </Link>
              </li>
            </ul>
          </div>
        )} */}

        {/* ------------- Picture menu------------- */}
        <div className="navbar-end">
          <h3 className="text-sm px-3 hidden lg:block text-[#FFCC40]">
            {userData?.email}
          </h3>
          {loggedIn && (
            <div className="dropdown dropdown-end">
              <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                  <img
                    src={
                      userData.image
                        ? baseURL + userData.image
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMpK_Cmq0XlLag7NmaAO_iqE80zoFyrK8yNzh7nT1Hswv3JZ7KjaLksLkc4lmWtouWxls&usqp=CAU"
                    }
                  />
                </div>
              </label>
              <ul
                tabIndex="0"
                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 text-gray-700"
              >
                <li className="font-bold text-black">
                  <Link to="/my-account">
                    {/*{userData?.first_name}*/}
                    My Account
                  </Link>
                </li>

                <li className="text-xs block font-bold lg:hidden text-black">
                  <a href="/#">{userData?.email}</a>
                </li>
                {paidSubscription ? (
                  <></>
                ) : (
                  <li>
                    <Link className="text-black font-bold" to="/upgrade">
                      Upgrade to Pro
                    </Link>
                  </li>
                )}
                {/* <li><a>Settings</a></li> */}
                <li
                  onClick={handleLogout}
                  className="text-red-500
                                    hover:text-red-600"
                >
                  <a>Logout</a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
